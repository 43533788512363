exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Register_container__3tJlp {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.Register_bold__3lgRS {\n  font-size: 46px;\n  font-weight: bold;\n  color: #000000;\n}\n.Register_form__2HWag {\n  justify-content: center;\n  align-items: center;\n  position: relative;\n  bottom: 30px;\n}\n\n\n.Register_textField__Gz8Ur {\n  width: 500px;\n}\n\n.Register_label__3e9a- {\n  color: #6b6c6f;\n}\n\n.Register_buttonDiv__1TwNv {\n  width: 500px;\n  display: flex;\n  justify-content: space-between;\n  margin-top: 30px;\n}\n\n.Register_button__2GdXF {\n  width: 220px;\n  height: 50px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "Register_container__3tJlp",
	"bold": "Register_bold__3lgRS",
	"form": "Register_form__2HWag",
	"textField": "Register_textField__Gz8Ur",
	"label": "Register_label__3e9a-",
	"buttonDiv": "Register_buttonDiv__1TwNv",
	"button": "Register_button__2GdXF"
};