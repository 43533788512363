import React, { Component } from 'react';

import PrintTemplate from 'react-print'
import styles from './Receipt.module.css'
import polonorumLogo from '../../assets/Polonorum_logo.png'
import ReactToPrint from 'react-to-print'

class Receipt extends Component {
  constructor(props) {
    super(props)
    this.state = {
      billName: "Claire Koperwas",
      billAdr: "6732 Mount Pakron Drive",
      billCity: "San Jose",
      billState: "CA",
      billZip: "95120",
      shipName: "Claire Koperwas",
      shipAdr: "6732 Mount Pakron Drive",
      shipCity: "San Jose",
      shipState: "CA",
      shipZip: "95120",
      receiptNum: "12345678",
      date: '6/19/2019',
      poNum: '233230129',
      ccNum: '4242',
      price: 200,
      salesTax: 6.25

    }
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.infoContainer}>
          <div className={styles.shippingContainer}>
            <div className={styles.shipHalf}>
              <p className={styles.bold}>Bill to</p>
              <p>
                {this.props.billName}<br/>
                {this.props.billAdr}<br/>
                {this.props.billCity}, {this.props.billState} {this.props.billZip}
              </p>
            </div>
            <div className={styles.shipHalf}>
              <p className={styles.bold}>Ship to</p>
              <p>
                {this.props.shipName}<br/>
                {this.props.shipAdr}<br/>
                {this.props.shipCity}, {this.props.shipState} {this.props.shipZip}
              </p>

            </div>
          </div>
          <div className={styles.paymentContainer}>
            <div className={styles.paymentHalf}>
              <p className={styles.bold}>Receipt #</p>
              <p className={styles.bold}>Receipt Date</p>
              <p className={styles.bold}>P.O. #</p>
              <p className={styles.bold}>Credit Card #</p>
            </div>
            <div className={styles.paymentHalf}>
              <p>{this.props.receiptNum}</p>
              <p>{this.props.date}</p>
              <p>{this.props.poNum}</p>
              <p>**** {this.props.ccNum}</p>
            </div>
          </div>
        </div>
        <hr className={styles.line}/>
        <div className={styles.middleSection}>
          <div className={styles.middleLeft}>
            <div className={styles.columnTitles}>
              Description
            </div>
            <div className={styles.columnTitles}>
              QTY/TYPE
            </div>
          </div>
          <div style={{flex: 2}}>
          </div>
        </div>
        <hr className={styles.line}/>
        <div className={styles.salesContainer}>
          <div className={styles.salesHalf1}>
            <div className={styles.descriptions}>
              <p>Number of Boards:</p>
              <p>Number of Unique Parts:</p>
              <p>Number of SMT Parts:</p>
              <p>Number of Through-Hole Parts:</p>
              <p>Number of BGA Packages:</p>
              <p>Process:</p>
              <p>Assembly Side:</p>
            </div>
            <div className={styles.quantities}>
              <p>{this.props.quantity}</p>
              <p>{this.props.uniqueParts}</p>
              <p>{this.props.smtParts}</p>
              <p>{this.props.throughHoles}</p>
              <p>{this.props.bgaPackages}</p>
              <p>{this.props.process}</p>
              <p>{this.props.assemblySide}</p>
            </div>
          </div>
          <div className={styles.salesHalf2}>
            <div className={styles.half}>
              {/*
              <p>Assembly Total</p>
              <p>Setup Total</p>
              <p>Sales Tax(%)</p>
              */}
              <p className={styles.bold}>Total</p>
            </div>
            <div className={styles.half}>
              {/*
              <p>${this.state.price}</p>
              <p>$20</p>
              <p>{this.state.salesTax}</p>
              <p>{this.state.price + this.state.price * this.state.salesTax / 100}</p>
              */}
              <p>${this.props.total}</p>

            </div>
            
          </div>
        </div>
      </div>

    )
  }
}

export default Receipt
