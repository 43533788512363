exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Capabilities_container__3LX_W {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.Capabilities_topContainer__2b2kP {\n  flex: 1 1;\n  width: 80%;\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n}\n\n.Capabilities_bottomContainer__1ae2s {\n  flex: 1 1;\n  width: 80%;\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n}\n\n.Capabilities_textDiv__2OiGi {\n  width: 441px;\n  height: 333px;\n  background-color: #eceaea;\n  margin-top: 80px;\n  margin-left: 20px;\n  min-width: 450px;\n}\n\n.Capabilities_plain__2U0Fn {\n  font-size: 23px;\n  font-weight: 300;\n  letter-spacing: -0.1px !important;\n  position: relative;\n  bottom: 20px;\n  margin-left: 16px;\n}\n\n.Capabilities_bold__2aTBw {\n  font-size: 30px;\n  font-weight: bold;\n  letter-spacing: -0.1px !important;\n  margin-left: 16px;\n  position: relative;\n  bottom: 20px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "Capabilities_container__3LX_W",
	"topContainer": "Capabilities_topContainer__2b2kP",
	"bottomContainer": "Capabilities_bottomContainer__1ae2s",
	"textDiv": "Capabilities_textDiv__2OiGi",
	"plain": "Capabilities_plain__2U0Fn",
	"bold": "Capabilities_bold__2aTBw"
};