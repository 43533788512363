import React, { Component } from 'react';
import axios from 'axios'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import styles from "./misc.module.css"
import { bindActionCreators } from 'redux'
import { Provider, connect } from 'react-redux'
import {serverLink} from '../../constants/serverLink'
import {ToastsContainer, ToastsStore} from 'react-toasts';


const mapStateToProps = (state) => ({ user: state.authReducer.user })

class EditPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmPassword: '',
      newPassword: '',
      currentPassword: '',
    }
  }



  handleChange = (name) => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  onSaveClick = () => {
    const {currentPassword, newPassword, confirmPassword} = this.state

    if(newPassword !== confirmPassword || newPassword.length <= 0) {
      ToastsStore.warning("New passwords do not match")
    }
    else {
      axios.post(serverLink + 'changePassword', {
        userId: this.props.user.id,
        currentPassword,
        newPassword,
        confirmPassword,
      }).then((response) => {
        console.log(response);
        if(response.data.success) {
          ToastsStore.success("Successfully changed password")
          this.props.history.push("/profile")
        } else {
          ToastsStore.error("Could not changed password")
        }
      }).catch((err) => {
        console.log(err);
      })
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <p className={styles.redText}>Change Password</p>
        <TextField
          id="outlined-password-input"
          label="Old Password"
          className={styles.textField}
          type="password"
          autoComplete="current-password"
          margin="normal"
          variant="outlined"
          onChange={this.handleChange('currentPassword')}
          value={this.state.currentPassword}
        />
        <TextField
          id="outlined-password-input"
          label="New Password"
          className={styles.textField}
          type="password"
          autoComplete="current-password"
          margin="normal"
          variant="outlined"
          onChange={this.handleChange('newPassword')}
          value={this.state.newPassword}
        />
        <TextField
          id="outlined-password-input"
          label="Confirm New Password"
          className={styles.textField}
          type="password"
          autoComplete="current-password"
          margin="normal"
          variant="outlined"
          onChange={this.handleChange('confirmPassword')}
          value={this.state.confirmPassword}
        />

        <Button variant="contained" color="primary" 
          onClick={this.onSaveClick}
          style={{
            color: 'white', 
            marginTop: 15,
          }} 
        >
          Save Changes
        </Button>
      </div>
    )
  }
}

export default connect(mapStateToProps)(EditPassword)


