import React, { Component } from 'react';

import { bindActionCreators } from 'redux'
import { Provider, connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import {serverLink} from '../../constants/serverLink'
import OrderCard from './orderCard'
import ReactList from 'react-list';
import {userGet} from '../../redux/actions/authActions'
import ProjectHeader from './projectHeader.js'


import global from "../../App.module.css"
import styles from "./Profile.module.css"

const mapStateToProps = (state) => ({ user: state.authReducer.user })
const mapDispatchToProps = (dispatch) => {
  return {
    userGet: (user) => dispatch(userGet(user))
  }
}

class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orders: null
    }
  }

  componentDidMount() {
    console.log("Profile mounted");
    axios.post(serverLink + 'getUser', {
      userId: this.props.user.id
    }).then((response) => {
      console.log(response);
      this.setState({orders: response.data.orders})
      this.props.userGet(response.data.user)
    }).catch((err) => {
      console.log(err);
    })
  }

  onEditPassword = () => {
    this.props.history.push('/editPassword')
  }

  
  onEditShipping = () => {
    this.props.history.push('/editShipping')
  }

  onLastMonthClick = () => {
    this.props.history.push('/previousOrders')
  }

  render() {
    const orderLength = (this.state.orders) ? this.state.orders.length : 0
    return (
      <div className={styles.container}>
        <div className={styles.topPanels}>
          <div className={styles.accountInfo}>
            <p className={[styles.bold, styles.red, styles.underline, styles.marginLeft].join(' ')}>
              Login Credentials
            </p>
            <div className={styles.currentInfo}>
              <p className={[styles.bold, styles.red, styles.noMargin].join(' ')}>Email Address</p>
              <p className={[styles.text, styles.red, styles.noMargin].join(' ')}>{this.props.user.email}</p>

              <br/>
              <p className={[styles.bold, styles.red, styles.noMargin].join(' ')}>Password</p>
              <p className={[styles.text, styles.red, styles.noMargin].join(' ')}>*********</p>
            </div>
            <div className={styles.editButtons}>
              <p 
                className={styles.blueText}
                onClick={this.onEditPassword}
              >
                Edit
              </p>
            </div>
          </div>
          <div className={styles.billingInfo}>
            <p className={[styles.bold, styles.red, styles.underline, styles.rightMargin].join(' ')}>
              Contact/Shipping Info
            </p>
            <div className={styles.billingText}>
              {this.props.user.firstName && this.props.user.lastName ?
                <>
                  <p className={[styles.bold, styles.red, styles.noMargin].join(' ')}>
                    {this.props.user.firstName}{' '}{this.props.user.lastName}
                  </p>
                  </>
                  :
                  <>
                  <p className={[styles.bold, styles.red, styles.noMargin].join(' ')}>
                    Customer Name
                  </p>
                </>
              }
              {this.props.user.streetAddress && this.props.user.city && this.props.user.zipCode ?
                <>
                  <p className={[styles.text, styles.red, styles.noMargin].join(' ')}>
                    {this.props.user.streetAddress}{' '}{this.props.user.shippingApt}
                  </p>
                  <p className={[styles.text, styles.red, styles.noMargin].join(' ')}>
                    {this.props.user.city}{', '}{this.props.user.state}{' '}{this.props.user.zipCode}
                  </p>
                  <p className={[styles.text, styles.red, styles.noMargin].join(' ')}>
                    {this.props.user.country}
                  </p>
                </>
              :
                <>
                  <p className={[styles.text, styles.red, styles.noMargin].join(' ')}>
                    Shipping Address
                  </p>
                </>
              }
            </div>
            <div className={styles.addressEdit}>
              <p className={styles.blueText} onClick={this.onEditShipping}>Edit</p>
            </div>
          </div>
        </div>
        <div className={styles.orderPanel}>
          <ProjectHeader 
            headerName={'Active Projects'} 
            onHeaderButtonClick={this.onLastMonthClick}
            buttonTitle={'Previous Projects'}
            showEst={true}
          />
          <div className={styles.orderList}>
            {
              this.state.orders &&
              this.state.orders.map((order, index) => (
              <OrderCard 
                key={order.salesNum}
                orderId={order.id}
                orderStatus={order.status} 
                projectName={order.projectName} 
                progress={order.percentageComplete}
                error={order.errorInOrder}
                orderDate={order.shippingDate}
              />
            ))}

          </div>
        </div>
      </div>
    )
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Profile)
