exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Services_container__2DzL5 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.Services_topContainer__1lhUO {\n  flex: 1 1;\n  width: 80%;\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n}\n\n.Services_textDiv__2eaYz {\n  width: 441px;\n  height: 333px;\n  background-color: #eceaea;\n  justify-contnet: center;\n  margin-top: 80px;\n  margin-left: 20px;\n  min-width: 450px;\n}\n\n.Services_bottomContainer__91ukM {\n  flex: 1 1;\n  width: 80%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.Services_plain__3OvPB {\n  font-size: 23px;\n  font-weight: 300;\n  letter-spacing: -0.1px !important;\n  position: relative;\n  bottom: 20px;\n  margin-left: 16px;\n  margin-right: 16px;\n}\n\n.Services_bold__2SRVT {\n  font-size: 30px;\n  font-weight: bold;\n  letter-spacing: -0.1px !important;\n  margin-left: 16px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "Services_container__2DzL5",
	"topContainer": "Services_topContainer__1lhUO",
	"textDiv": "Services_textDiv__2eaYz",
	"bottomContainer": "Services_bottomContainer__91ukM",
	"plain": "Services_plain__3OvPB",
	"bold": "Services_bold__2SRVT"
};