exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Login_container__2Mvr1 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.Login_bold__mLEAZ {\n  font-size: 46px;\n  font-weight: bold;\n  color: #000000;\n}\n\n.Login_form__UVfpx {\n  justify-content: center;\n  align-items: center;\n  position: relative;\n  bottom: 30px;\n}\n\n.Login_textField__2_-xX {\n  width: 500px;\n}\n\n.Login_label__3eAkd {\n  position: relative;\n  top: 25px;\n  color: #6b6c6f;\n}\n\n.Login_buttonDiv__2SuOz {\n  width: 500px;\n  display: flex;\n  justify-content: space-between;\n  margin-top: 30px;\n}\n\n.Login_button__UmtTq {\n  width: 220px;\n  height: 50px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "Login_container__2Mvr1",
	"bold": "Login_bold__mLEAZ",
	"form": "Login_form__UVfpx",
	"textField": "Login_textField__2_-xX",
	"label": "Login_label__3eAkd",
	"buttonDiv": "Login_buttonDiv__2SuOz",
	"button": "Login_button__UmtTq"
};