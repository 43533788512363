import React, { Component } from 'react';
import axios from 'axios'
import './Email.css' 

const serverLink = 'http://localhost:3001'

class Email extends Component {
  constructor(props) {
    super(props)
    this.state = {
      subject: 'Subject Name',
      body: 'Write an Email',
    }
  }

  handleInputChange = (event) => {
    const target = event.target
    const name = target.name
    this.setState({[name]: event.target.value})
  }

  handleSubmit = (event) => {
    console.log("I can send the email with values", this.state.subject, this.state.body);
    axios.post(serverLink + '/email', {
      subject: this.state.subject,
      emailText: this.state.body,
    }).then((res) => {
      console.log(res);
    }).catch((err) => {
      console.log(err);
    })
    event.preventDefault() //Prevents refresh
  }

  render() {
    return (
      <div className="Container">
        <form className="Form" onSubmit={this.handleSubmit}>
          <label className="Label">
            Subject:
            <input
              name="subject"
              type="text"
              value={this.state.subject}
              onChange={this.handleInputChange}
            />
          </label>
          <label className="Label">
            Body:
            <textarea 
              name="body" 
              value={this.state.body} 
              onChange={this.handleInputChange}
            />
          </label>
          <input type="submit" value="Submit"/>
        </form>
      </div>
    )
  }
}

export default Email
