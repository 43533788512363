import actionCreator from './actionCreator'
import axios from 'axios'
import {serverLink} from '../../constants/serverLink.js'

//Action type constants
const USER_LOGGED_IN = 'USER_LOGGED_IN'
const USER_LOGGED_OUT = 'USER_LOGGED_OUT'
const USER_ERROR = 'USER_ERROR'
const USER_GET = 'USER_GET'



export function userSignUp(_email, _password) {
  return async(dispatch) => {
    console.log(serverLink)
    await axios.post(serverLink + "signup", {
      username: _email,
      password: _password
    }).then(async (response) => {
      console.log(response);
      if(response.data.user) {
        await dispatch(userLoggedIn(response.data.user, ''))
      }
      else {
        dispatch(userError(response.data.response))
      }
    }).catch((err) => {
      console.log(err.response)
      if(err.response) {
        dispatch(userError(err.response.data.error))
      }
      else {
        dispatch(userError("NO RESPONSE FROM SERVER"))
      }
    })
  }
}

export function userLogIn(_email, _password) {
  return async(dispatch) => {
    await axios.post(serverLink + "login", {
      username: _email,
      password: _password
    }).then(async (response) => {
      console.log("RESPONSE STATEMENT");
      console.log(response)
      if(response.data) {
        if(response.data.user) {
          await dispatch(userLoggedIn(response.data.user, response.data.jwt))
        }
        else {
          dispatch(userError(response.data.response))
        }
      }
    }).catch((err) => {
      console.log("Catch statement")
      console.log(err.response)
      if(err.response) {
        dispatch(userError(err.response.data.error))
      }
      else {
        dispatch(userError("NO RESPONSE FROM SERVER"))
      }
    })
  }
}

export const userLoggedIn = actionCreator(USER_LOGGED_IN, 'user', 'jwt')
export const userLoggedOut = actionCreator(USER_LOGGED_OUT)
export const userError = actionCreator(USER_ERROR, 'errMsg')
export const userGet = actionCreator(USER_GET, 'user')

const authActions = {
  userSignUp,
  userLogIn,
  userLoggedIn,
  userLoggedOut,
  userError,
  userGet,

}

export default authActions
