import React, { Component } from 'react';
import axios from 'axios'
import { bindActionCreators } from 'redux'
import { Provider, connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import FileCard from './fileCard'
import DeleteIcon from '@material-ui/icons/Delete';

import {serverLink} from '../../constants/serverLink'
import styles from './OrderFiles.module.css'

const mapStateToProps = (state) => ({ user: state.authReducer.user,})

class OrderFiles extends Component {
  constructor(props) {
    super(props)
    this.state = {
      deleteSelected: false,
    }
  }

  componentDidMount() {
    this.getFilenames();
  }

  getFilenames = () => {
    const {orderId} = this.props.match.params
    axios.post(serverLink + 'getFileNames', {
      userId: this.props.user.id,
      orderId: orderId,
    }).then((response) => {
      console.log(response);
      this.setState({
        filenames: response.data.filenames, 
        //Checkboxes is an array that holds the values of the checkboxes for fileCard list
        checkboxes: Array(response.data.filenames.length).fill(false)
      })
    }).catch((err) => {
      console.log(err);
    })

  }

  handleCheck = (index) => {
    //We can modify the array directly since the address is what is stored in the state
    this.state.checkboxes[index] = !this.state.checkboxes[index]

    //Update whether to show the delete icon
    const deleteSelected = this.state.checkboxes.some(function(element) {
      return element == true
    })
    //We need to trigger a re-render,
    //we don't need to pass the checkbox array, its a pointer so the value is already stored.

    this.setState({deleteSelected})
  }

  handleDeleteClick = () => {
    const {orderId} = this.props.match.params
    const {checkboxes, filenames} = this.state
    let filesToDelete = []
    for(let i = 0; i < checkboxes.length; i++) {
      if(checkboxes[i]) {
        filesToDelete.push(filenames[i])
      }
    }
    console.log(filesToDelete);
    axios.post(serverLink + 'deleteFiles', {
      userId: this.props.user.id,
      orderId: orderId,
      filenames: filesToDelete,
    }).then((response) => {
      console.log(response);
      if(response.data.success) {
        this.getFilenames()
        this.setState({deleteSelected: false})
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  handleUploadClick = () => {
    this.props.history.push("/uploadFiles/" + this.props.match.params.orderId)
  }

  render() {
    const {orderId} = this.props.match.params
    return (
      <div className={styles.container}>
        <div className={styles.fileContainer}>
          <div className={styles.header}>
            <div className={styles.leftHeader}>
              <p className={styles.projectText}>Project # {orderId}</p>
            </div>
            <div className={styles.rightHeader}>
              {this.state.deleteSelected ?
                <DeleteIcon 
                  style={{
                    width: '40px',
                    height: '50px',
                  }}
                  onClick={this.handleDeleteClick}
                />
                :
                <Button variant="contained" color="primary" 
                  style={{backgroundColor: '#4285f4', 
                      color: 'white', 
                      width: '190px',
                      height: '60px',
                  }} 
                  onClick={this.handleUploadClick}
                >
                  Upload Files
                </Button>
              }
            </div>
          </div>
          <div className={styles.bottomContainer}>
            <p className={styles.recentText}>Recently Uploaded</p>
            {
              this.state.filenames &&
              this.state.filenames.map((filename, index) => (
                <FileCard
                  filename={filename}
                  userId={this.props.user.id}
                  orderId={orderId}
                  key={index}
                  index={index}
                  handleCheck={this.handleCheck}
                  checkValue={this.state.checkboxes[index]}
                />
              ))}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(OrderFiles)
