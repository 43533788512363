import React, { Component } from 'react';
import global from "../../App.module.css"
import styles from "./OrderPage.module.css"
import FileUpload from "../fileUpload/FileUpload.js"
import Receipt from "../receipt/Receipt.js"
import Payment from "../payment/Payment.js"
import Confirm from "../confirm/Confirm.js"
import Button from '@material-ui/core/Button'
import {Elements} from 'react-stripe-elements';
import {injectStripe} from 'react-stripe-elements';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { css } from '@emotion/core'
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';

import {serverLink} from '../../constants/serverLink'

//Style override for bar component
//Cant figure out how to do it in module file
const override = css`
  position: relative;
  left: 215px;
  top: 20px;
`

class Order extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      files: [],
      binaryFiles: [],
      firstName: '',
      busFirstName: '',
      lastName: '',
      busLastName: '',
      address: '',
      busAddress: '',
      apt: '',
      busApt: '',
      city: '',
      busCity: '',
      state: 'CA',
      busState: 'CA',
      country: 'US',
      busCountry: 'US',
      zip: '',
      busZip: '',
      projectName: '',
      purchaseOrder: '',

      nextButtonText: 'Next',
      prevButtonText: 'Prev',

      creatingOrder: false,
      orderError: false,
    }
  }


  componentDidUpdate(prevProps, prevState) {
    if((prevState.creatingOrder == true && this.state.creatingOrder == false)
        && this.state.orderError == false)
    {
      console.log("Got the order done");
      this.setState({page: 3})
    }
  }

  handleTextField = (name) => event => {
    console.log("FROM ORDER PAGE", name, event.target.value);
    this.setState({
      [name]: event.target.value
    })
  }

  onDrop = async (acceptedFiles) => {
    let binaryFiles = []
    
    for(const file of acceptedFiles) {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result
      //console.log(binaryStr)
      let binaryFile = {filename: file.name, contentType: file.type, data: binaryStr}
      binaryFiles.push(binaryFile)
      }
      await reader.readAsBinaryString(file)

    }
    console.log("BINARY FILES");
    console.log(binaryFiles);
    console.log("AcceptedFiles");
    console.log(acceptedFiles);
    this.setState({files: acceptedFiles, binaryFiles})
  }

  handleCheck = name => event => {
    if(event.target.checked) {
      this.setState({
        busFirstName: this.state.firstName,
        busLastName: this.state.lastName,
        busAddress: this.state.address,
        busApt: this.state.apt,
        busCity: this.state.city,
        busState: this.state.state,
        busCountry: this.state.country,
        busZip: this.state.zip,
      })
    }
  }

  onNext = async () => {
    const {
      purchaseOrder, projectName,
      firstName, busFirstName, lastName, busLastName, apt, busApt,
      address, busAddress, city, busCity, country, busCountry, state, busState,
      zip, busZip} = this.state

    let page = this.state.page;
    console.log(page);
    if (page == 0) {
      if(projectName === '' || purchaseOrder === '') {
        ToastsStore.warning("Please fill out the project name and the purchase order")
      }
      else {
        this.setState({page: 1})
      }
    }
    else if (page == 1) {
      if(
        firstName === '' ||
        busFirstName === '' ||
        lastName === '' ||
        busLastName === '' ||
        address === '' ||
        busAddress === '' ||
        zip === '' ||
        busZip === '') {

          
          ToastsStore.warning("Please fill out all fields")
      }
      else {
        await this.props.stripe.createToken({type: 'card'}).then(function(result) {
            console.log(result);
            if(result.token) {
              console.log("GOOD");
              this.setState({token: result.token, page: 2})
            }
            else {
              console.log("BAD");
              ToastsStore.warning("There seems to be an issue with the credit card information.")
              page--; //STOP page from moving on to next page if the token isn't made
            }
          }.bind(this));
      }

    }

    else if(page == 2) {
      this.createOrder()
    }
    else if (page == 3) {
      this.props.history.push('/')
    }
  }

  onPrev = () => {
    let page = this.state.page - 1;
    if (page <= 0) {
      this.props.goBackToQuote()
      page = 0;
    }
    this.setState({page});
  }

  createOrder = () => {
    if(!this.state.creatingOrder) {
      this.setState({creatingOrder: true})
      const {firstName, lastName, address, apt, city, state, zip} = this.state
      const {busFirstName, busLastName, busAddress, busApt, busCity, busState, busZip} = this.state

      let shippingAdr = address
      if(apt && apt.length > 0) {
        shippingAdr += ' #' + apt
      }
      shippingAdr += ', ' + city + ', ' + state + ' ' + zip
      let billingAdr = busAddress
      if(busApt && busApt.length > 0) {
        billingAdr += ' #' + busApt
      }
      billingAdr += ', ' + busCity + ', ' + busState + ', ' + busZip

      console.log(shippingAdr);
      console.log(billingAdr);
      console.log(this.state.binaryFiles);
      console.log(this.state.token);
      console.log(this.props.userID);
      console.log(this.props.processType);

      let shipName = firstName + ' ' + lastName
      let billingName = busFirstName + ' ' + busLastName

      axios.post(serverLink + "newOrder", {
        shippingName: shipName,
        billingName: billingName,
        purchaseOrder: this.state.purchaseOrder,
        projectName: this.state.projectName,
        shippingAdr: shippingAdr,
        billingAdr: billingAdr,
        stripeToken: this.state.token,
        files: this.state.binaryFiles,
        boardQuantity: this.props.boardQuantity,
        uniqueParts: this.props.uniqueParts,
        smtParts: this.props.smtParts,
        throughHoles: this.props.throughHoles,
        bgaPackages: this.props.bgaPackages,
        processType: this.props.ledProcess,
        assemblySide: this.props.assemblySide,
        totalCost: this.props.totalCost,
        days: this.props.days,
        userID: this.props.userID,
      }, {
      }).then((response) => {
        console.log(response);
        if(response.data.success) {
          ToastsStore.success(response.data.message)
          console.log(response.data.order);
          let today = new Date();
          this.setState({
            receiptNum: response.data.order.salesNum,
            date: (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear(),
          })

          this.setState({creatingOrder: false, orderError: false})
          return true
        } else {
          ToastsStore.error(response.data.message)
          this.setState({creatingOrder: false, orderError: true})
          return false
        }
      }).catch((error) => {
        console.log(error);
        this.setState({creatingOrder: false, orderError: true})
        ToastsStore.error("Network Error")
        return false
      })
    } else {
      console.log("DID NOT TRY CREATING ORDER");
      return false;
    }
  }

  render() {
    const nextButtonText = this.state.page == 2 ? 'Confirm' :
                           this.state.page == 3 ? 'Back Home' :
                           'Next'
    const {firstName, lastName, address, apt, city, state, country, zip,
      busFirstName, busLastName, busAddress, busApt, busCity, busState, busCountry, busZip,} = this.state
    const page = this.state.page
    return (
      <LoadingOverlay
        active={this.state.creatingOrder}
        spinner
        text="Processing Order"
      >
        <div className={styles.container}>
          {//Show specific page
            page === 0 ?
            <FileUpload onDrop = {this.onDrop} files={this.state.files} handleTextField={this.handleTextField} projectName={this.state.projectName} purchaseOrder={this.state.purchaseOrder}/>
            :
            page === 1 ?
              <Payment firstName={this.state.firstName} lastName={this.state.lastName}
                address={this.state.address} apt={this.state.apt} city={this.state.city}
                state={this.state.state} country={this.state.country} zip={this.state.zip}
                busFirstName={this.state.busFirstName} busLastName={this.state.busLastName}
                busAddress={this.state.busAddress} busApt={this.state.busApt} 
                busCity={this.state.busCity} busState={this.state.busState} 
                busCountry={this.state.busCountry} busZip={this.state.busZip}
                cardNumber={this.state.cardNumber} expMonth={this.state.expMonth} 
                expYear={this.state.expYear} securityCode={this.state.securityCode}
                handleTextField={this.handleTextField} handleCheck={this.handleCheck}
                
              />

            :
            page === 2 ?
            <Confirm firstName={firstName} lastName={lastName} address={address}
             apt={apt} city={city} zip={zip} busFirstName={busFirstName} busLastName={busLastName}
             busAddress={busAddress} busApt={busApt} busCity={busCity} busZip={busZip} 
             last4={this.state.token.card.last4}/>
            :
            page === 3 ?
            <Receipt
              billName={busFirstName + busLastName}
              billAdr={busAddress}
              billCity={busCity}
              billState={busState}
              billZip={busZip}
              shipName={firstName + lastName}
              shipAdr={address}
              shipCity={city}
              shipZip={zip}
              receiptNum={this.state.receiptNum}
              date={this.state.date}
              poNum={this.state.purchaseOrder}
              ccNum={this.state.token.card.last4}
              quantity={this.props.boardQuantity}
              uniqueParts={this.props.uniqueParts}
              smtParts={this.props.smtParts}
              throughHoles={this.props.throughHoles}
              bgaPackages={this.props.bgaPackages}
              process={this.props.ledProcess}
              assemblySide={this.props.assemblySide}
              total={this.props.totalCost}
            />
            :
            <div>I AM NOTHING</div>
          }
          {this.state.page != 3 ?
            <div className={styles.buttonDiv}>
              <Button 
               variant="contained" color="primary" 
               className={styles.button} onClick={this.onPrev}
              >
                Prev
              </Button>
              <Button variant="contained" color="primary" 
                      className={styles.button} onClick={this.onNext} disabled={this.state.creatingOrder}
              >
                {nextButtonText}
              </Button>
            </div>
            :
            <div className={styles.singleButtonDiv}>
              <Button 
                variant="contained" color="primary" 
                className={styles.button} 
                onClick={this.onNext} 
                disabled={this.state.creatingOrder}
              >
                {nextButtonText}
              </Button>
            </div>
          }
        </div>
      </LoadingOverlay>
    )

  }
}

export default injectStripe(Order)
