exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".App_container__I_z-T {\n  display: flex;\n  flex-direction: column;\n  min-width: 800px;\n}\n\n.App_font__3Khjq {\n  font-style: normal;\n  font-family: Roboto;\n  font-stretch: normal;\n  line-height: normal;\n  letter-spacing: normal;\n}\n", ""]);

// exports
exports.locals = {
	"container": "App_container__I_z-T",
	"font": "App_font__3Khjq"
};