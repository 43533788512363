import React, { Component } from 'react';


import styles from "./Services.module.css"
import global from "../../App.module.css"

class Services extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <div className={styles.textDiv}>
            <p className={[global.font, styles.bold].join(' ')}>PCB Assembly</p>
            <p className={[global.font, styles.plain].join(' ')}>
              Our 'Fast Response' service is optimized for customers who seek low volume 
              PCB assembly, particularly where iterative development is required.
              <br/><br/>
              We are also flexible; able to provide turn-key, consignment, and mixed operations.

            </p>
          </div>
          <div className={styles.textDiv}>
            <p className={[global.font, styles.bold].join(' ')}>Real Time Quotation</p>
            <p className={[global.font, styles.plain].join(' ')}>
              Obtain an instant online quote that allows
              you to independently generate and
              compare the assembly cost and lead time in a 
              matter of seconds.
              <br/><br/>
              When you have concern about current or future component availability, 
              take advantage of our sourcing capability to ensure manufacturability.
            </p>
          </div>
          <div className={styles.textDiv}>
            <p className={[global.font, styles.bold].join(' ')}>PCB Sources</p>
            <p className={[global.font, styles.plain].join(' ')}>
              We have established resources in place to efficiently source PCB Fabs 
              worldwide from leading suppliers in the USA, Europe, and the Far East, 
              ensuring that we can meet your specific requirements in a timely manner.
            </p>
          </div>
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.textDiv}>
            <p className={[global.font, styles.bold].join(' ')}>Parts Kitting</p>
            <p className={[global.font, styles.plain].join(' ')}>
              We will provide a production ready kit of
              electronic components based on your
              BOM, with cost-effective pricing in less than 24
              hours. Our 'Fast Track' deliveries ensure that our customers enjoy
              all the benefits of a single source for their parts kitting needs.
            </p>
          </div>
          <div className={styles.textDiv}>
            <p className={[global.font, styles.bold].join(' ')}>Material Management</p>
            <p className={[global.font, styles.plain].join(' ')}>
              We handle all vendor relationships, parts <br />
              supply, delivery, and pricing throughout the
              product’s lifecycle.
              <br/><br/>
              Our continuous monitoring of part
              availability and pricing fluctuations, available through your online portal
              ensure your product pricing and Lead time is always visible
            </p>
          </div>
          <div className={styles.textDiv}>
            <p className={[global.font, styles.bold].join(' ')}>Engineering Services</p>
            <p className={[global.font, styles.plain].join(' ')}>
              Our Engineering services can be structured to include a full product
              development solution, from concept to final productionm including all 
              aspects of Hardware and Software. We can also provide testing and integration of
              your PCB solution into the final product.
            </p>
          </div>


        </div>
      </div>
    )
  }
}

export default Services
