import React, { Component } from 'react';

import styles from "./Login.module.css"
import global from "../../App.module.css"
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { bindActionCreators } from 'redux'
import { Provider, connect } from 'react-redux'
import * as authActions from '../../redux/actions/authActions.js'


const mapStateToProps = (state) => ({ user: state.authReducer, madeQuotation: state.orderReducer.madeQuotation})
const mapDispatchToProps = (dispatch) => bindActionCreators(authActions, dispatch)

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      error: false,
      errMsg: '',

    }
  }

  componentDidMount() {
    this.props.userLoggedOut();
  }

  componentDidUpdate(prevProps, prevState) {
    if(!prevProps.user.loggedIn && this.props.user.loggedIn) {
      if(this.props.madeQuotation) {
        this.props.history.push("/quotation")
      }
      else {
        this.props.history.push("/")
      }
    }
    else if( this.props.user.error && this.props.user.errMsg != this.state.errMsg) {
      this.setState({errMsg: prevProps.user.errMsg, error: true})
    }
  }

  handleChange = (name) => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  onLoginClick = () => {

    const {email, password} = this.state

    if(email === '' || password === '')  {
      this.setState({error: true, errMsg: 'Please fill out all fields!'})
    }
    else {
      this.props.userLogIn(this.state.email, this.state.password)
    }
  }

  onRegisterClick = () => {
    console.log("Clicked register")
    this.props.history.push('/register');
  }

  render () {
    return (
      <div className={styles.container}>
        <p className={[styles.bold, global.font].join(' ')}>Login to your account</p>
        <form className={styles.form}>
          <p className={styles.label}>E-Mail</p>
          <TextField
            id="Email"
            className={styles.textField}
            value={this.state.email}
            onChange={this.handleChange('email')}
            margin="normal"
            variant="outlined"
          />  
          <p className={styles.label}>Password</p>
          <TextField
            id="Password"
            className={styles.textField}
            value={this.state.password}
            onChange={this.handleChange('password')}
            margin="normal"
            type="password"
            variant="outlined"
          />
          <div className={styles.buttonDiv}>
            <Button variant="outlined" color="primary" className={styles.button} onClick={this.onRegisterClick}>
              Register
            </Button>
            <Button variant="contained" color="primary" className={styles.button} onClick={this.onLoginClick}>
              Login
            </Button>
          </div>
        </form>
        { this.state.error &&
          <div>
            {this.state.errMsg}
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
