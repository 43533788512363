/*
TODO

  MAKE SURE ALL FIELDS ARE VALID
*/

import React, {Component } from 'react';

import styles from "./Payment.module.css"
import global from "../../App.module.css"

import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button'
import {CardElement} from 'react-stripe-elements';
import {CardNumberElement} from 'react-stripe-elements';
import {CardExpiryElement} from 'react-stripe-elements';
import {CardCVCElement} from 'react-stripe-elements';

import {countries, states} from '../../constants/countryState.js';



                
class Payment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      saveCheck: false,
    }
  }
  handleChange = (name) => event => {
    if(event.target.value === "saveCheck") {
      this.setState({
        [name]: event.target.checked
      })
    }
    else {
      this.setState({
        [name]: event.target.value
      })
    }
  }
  render() {
    return (
      <div className={styles.container}>
        <ExpansionPanel className={styles.panel} defaultExpanded={true}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Shipping Address</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={styles.panelDetails}>
            <div className={styles.panelDiv}>
              <TextField
                id="outlined-with-placeholder"
                label="First Name"
                InputLabelProps={{shrink: true}}
                placeholder="First Name"
                margin="normal"
                variant="outlined"
                value={this.props.firstName}
                onChange={this.props.handleTextField('firstName')}
                className={styles.field}
              />
              
              <TextField
                id="outlined-with-placeholder"
                label="Last Name"
                InputLabelProps={{shrink: true}}
                placeholder="Last Name"
                margin="normal"
                variant="outlined"
                value={this.props.lastName}
                onChange={this.props.handleTextField('lastName')}
                className={styles.field}
              />
              {//TODO MAKE SURE THIS IS A VALID ADDRESS
              }
              <TextField
                id="outlined-with-placeholder"
                label="Address"
                InputLabelProps={{shrink: true}}
                placeholder="Address"
                margin="normal"
                variant="outlined"
                value={this.props.address}
                onChange={this.props.handleTextField('address')}
                className={styles.field}
              />
              <TextField
                id="outlined-with-placeholder"
                label="Apt, suite, ect. (optional)"
                InputLabelProps={{shrink: true}}
                placeholder="Apt, suite, ect. (optional)"
                margin="normal"
                variant="outlined"
                value={this.props.apt}
                onChange={this.props.handleTextField('apt')}
                className={styles.field}
              />

              <TextField
                id="outlined-with-placeholder"
                label="City"
                InputLabelProps={{shrink: true}}
                placeholder="City"
                margin="normal"
                variant="outlined"
                value={this.props.city}
                onChange={this.props.handleTextField('city')}
                className={styles.field}
              />
              <div className={styles.rowDiv}>
                <TextField
                  id="outlined-with-placeholder"
                  label="State"
                  select
                  InputLabelProps={{shrink: true}}
                  placeholder="State"
                  margin="normal"
                  variant="outlined"
                  value={this.props.state}
                  onChange={this.props.handleTextField('state')}
                  SelectProps={{
                  native: true,
                  MenuProps: {
                    className: styles.menu,
                  },
                }}

                  className={styles.stateField}
                >
                  {states.map(option => (
                  <option key={option.abbreviation} value={option.abbreviation}>
                    {option.abbreviation}
                  </option>
                ))}
                </TextField>

                <TextField
                  id="outlined-with-placeholder"
                  select
                  label="Country"
                  InputLabelProps={{shrink: true}}
                  placeholder="Country"
                  margin="normal"
                  variant="outlined"
                  value={this.props.country}
                  onChange={this.props.handleTextField('country')}
                  className={styles.countryField}
                  SelectProps={{
                  native: true,
                  MenuProps: {
                    className: styles.menu,
                  },
                }}
              >
                {countries.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              </div>
              <TextField
                id="outlined-with-placeholder"
                label="Zip Code"
                InputLabelProps={{shrink: true}}
                type="number"
                placeholder="Zip Code"
                margin="normal"
                variant="outlined"
                value={this.props.zip}
                onChange={this.props.handleTextField('zip')}
                className={styles.field}
              />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {//TODO ADD SAME AS Shipping Address Options
        }
        <ExpansionPanel className={styles.panel} defaultExpanded={true}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Billing Address</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={styles.panelDetails}>
            <div className={styles.panelDiv}>
              <div>
              Same as Shipping?
              <Checkbox
                checked={this.state.checkedB}
                onChange={this.props.handleCheck('checkedB')}
                value="checkedB"
                color="primary"
              />
              </div>
                
              <TextField
                id="outlined-with-placeholder"
                label="First Name"
                InputLabelProps={{shrink: true}}
                placeholder="First Name"
                margin="normal"
                variant="outlined"
                value={this.props.busFirstName}
                onChange={this.props.handleTextField('busFirstName')}
                className={styles.field}
              />
              <TextField
                id="outlined-with-placeholder"
                label="Last Name"
                InputLabelProps={{shrink: true}}
                placeholder="Last Name"
                margin="normal"
                variant="outlined"
                value={this.props.busLastName}
                onChange={this.props.handleTextField('busLastName')}
                className={styles.field}
              />
              {//TODO MAKE SURE THIS IS A VALID ADDRESS
              }
              <TextField
                id="outlined-with-placeholder"
                label="Address"
                InputLabelProps={{shrink: true}}
                placeholder="Address"
                margin="normal"
                variant="outlined"
                value={this.props.busAddress}
                onChange={this.props.handleTextField('busAddress')}
                className={styles.field}
              />
              <TextField
                id="outlined-with-placeholder"
                label="Apt, suite, ect. (optional)"
                InputLabelProps={{shrink: true}}
                placeholder="Apt, suite, ect. (optional)"
                margin="normal"
                variant="outlined"
                value={this.props.busApt}
                onChange={this.props.handleTextField('busApt')}
                className={styles.field}
              />

              <TextField
                id="outlined-with-placeholder"
                label="City"
                InputLabelProps={{shrink: true}}
                placeholder="City"
                margin="normal"
                variant="outlined"
                value={this.props.busCity}
                onChange={this.props.handleTextField('busCity')}
                className={styles.field}
              />
              <div className={styles.rowDiv}>
                <TextField
                  id="outlined-with-placeholder"
                  label="State"
                  select
                  InputLabelProps={{shrink: true}}
                  placeholder="State"
                  margin="normal"
                  variant="outlined"
                  value={this.props.busState}
                  onChange={this.props.handleTextField('busState')}
                  SelectProps={{
                  native: true,
                  MenuProps: {
                    className: styles.menu,
                  },
                }}

                  className={styles.stateField}
                >
                  {states.map(option => (
                  <option key={option.abbreviation} value={option.abbreviation}>
                    {option.abbreviation}
                  </option>
                  ))}
                </TextField>

                <TextField
                  id="outlined-with-placeholder"
                  label="Country"
                  select
                  InputLabelProps={{shrink: true}}
                  placeholder="Country"
                  margin="normal"
                  variant="outlined"
                  value={this.props.busCountry}
                  onChange={this.props.handleTextField('busCountry')}
                  SelectProps={{
                  native: true,
                  MenuProps: {
                    className: styles.menu,
                  },
                  }}
                  className={styles.countryField}
                >
                  {countries.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                  ))}

                </TextField>
              </div>
              <TextField
                id="outlined-with-placeholder"
                label="Zip Code"
                InputLabelProps={{shrink: true}}
                placeholder="Zip Code"
                margin="normal"
                variant="outlined"
                value={this.props.busZip}
                onChange={this.props.handleTextField('busZip')}
                className={styles.field}
                type="number"
              />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel className={styles.panel} defaultExpanded={true}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            expanded={true}
          >
            <Typography>Payment Information</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={styles.panelDetails}>
      {//TODO MAKE THESE styles GO TO CSS MODULE
      }
            <div className={styles.panelDiv}>
              <label style={{borderStyle: 'solid', borderWidth: '1px', borderColor: '#C4C4C4',
                borderRadius: '5px', width: '80%', fontFamily: "Roboto", fontWeight: '350'}}>
                <div style={{padding: '10px',}} >
                  Card Number
                  <CardNumberElement style={{base: { fontFamily: 'Roboto', fontSize: '18px',}}}/>
                </div>
              </label>
              <div className={[styles.rowDiv, styles.marginRow].join(' ')}>
                <label style={{borderStyle: 'solid', borderWidth: '1px', borderColor: '#C4C4C4',
                  borderRadius: '5px', width: '40%', fontFamily: "Roboto", fontWeight: '350'}}>
                  <div style={{padding: '10px'}}>
                    Exp Date
                    <CardExpiryElement style={{base: { fontSize: '16px'}}}/>
                  </div>
                </label>
                <label style={{borderStyle: 'solid', borderWidth: '1px', borderColor: '#C4C4C4',
                  borderRadius: '5px', width: '40%', fontFamily: "Roboto", fontWeight: '350'}}>
                  <div style={{padding: '10px'}} >
                  Security Code
                  <CardCVCElement style={{base: { fontSize: '16px'}}}/>
                  
                  </div>
                </label>
                
              </div>
                <p style={{textAlign: 'center'}}>
                  When using this guest account <br/>
                  use the card number 4242-4242-4242-4242 <br/>
                  the expiration date and security code can by anything
                </p>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>


      </div>
    )
  }
}

export default Payment

/*
<TextField
                id="outlined-with-placeholder"
                label="Card Number"
                placeholder="Card Number"
                margin="normal"
                variant="outlined"
                value={this.state.cardNumber}
                onChange={this.props.handleTextField('cardNumber')}
                className={styles.field}
              />
              <div className={styles.rowDiv}>
                <TextField
                  id="outlined-with-placeholder"
                  label="Expiration Month"
                  placeholder="Expiration Month"
                  margin="normal"
                  variant="outlined"
                  value={this.state.expMonth}
                  onChange={this.props.handleTextField('expMonth')}
                  className={styles.expField}
                />
                <TextField
                  id="outlined-with-placeholder"
                  label="Expiration Year"
                  placeholder="Expiration Year"
                  margin="normal"
                  variant="outlined"
                  value={this.state.expYear}
                  onChange={this.props.handleTextField('expYear')}
                  className={styles.expField}
                />
              </div>
              <TextField
                id="outlined-with-placeholder"
                label="Security Code"
                placeholder="Security Code"
                margin="normal"
                variant="outlined"
                value={this.state.securityCode}
                onChange={this.props.handleTextField('securityCode')}
                className={styles.field}
              />
            }

*/
