const initialState = {
  orderName: '',
  purchaseOrder: '',
  files: [],
  stripeToken: '',
  quantity: 0,
  uniqueParts: 0,
  smtParts: 0,
  throughHoles: 0,
  bgaPackages: 0,
  process: 'LED process',
  side: 'Top Side',
  orderTime: 5, //Num days
  totalCost: 0,
  madeQuotation: false,
}

export default function reducer(state = initialState, action) {
  const {type, orderName, purchaseOrder, files, stripeToken,
    uniqueParts, smtparts, throughHoles, bgaPackages, process,
    orderTime, totalCost, quantity, smtParts, side, madeQuotation } = action

  switch(type) {
    case "SAVE_QUOTATION":
      return{...state, quantity, uniqueParts, smtParts, throughHoles, bgaPackages,
        process, side, orderTime, totalCost,}

    case "USER_NEEDS_LOGIN":
      return {...state, madeQuotation}
    default: 
      return state

  }
}
