import React, { Component } from 'react';
import axios from 'axios'
import Button from '@material-ui/core/Button'
import styles from "./misc.module.css"
import TextField from '@material-ui/core/TextField'
import { bindActionCreators } from 'redux'
import { Provider, connect } from 'react-redux'
import {serverLink} from '../../constants/serverLink'
import {ToastsContainer, ToastsStore} from 'react-toasts';
import {countries, states} from '../../constants/countryState.js';
import {userGet} from '../../redux/actions/authActions'

const mapStateToProps = (state) => ({ user: state.authReducer.user })
const mapDispatchToProps = (dispatch) => {
  return {
    userGet: (user) => dispatch(userGet(user))
  }
}

class EditShipping extends Component {
  constructor(props) {
    super(props)
    console.log('HELLO', this.props.user.firstName);
    this.state = {
      firstName: this.props.user.firstName ? this.props.user.firstName : '',
      lastName: this.props.user.lastName ? this.props.user.lastName : '',
      address: this.props.user.streetAddress ? this.props.user.streetAddress : '',
      apt: this.props.user.shippingApt ? this.props.user.shippingApt : '',
      city: this.props.user.city ? this.props.user.city : '',
      state: this.props.user.state ? this.props.user.state : 'CA',
      country: this.props.user.country ? this.props.user.country : 'United States',
      zip: this.props.user.zipCode ? this.props.user.zipCode : '',
    }
  }

  handleTextField = (name) => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  onSaveClick = () => {
    axios.post(serverLink + 'changeShipping', {
      userId: this.props.user.id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      streetAddress: this.state.address,
      shippingApt: this.state.apt,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      zipCode: this.state.zip,
    }).then((response) => {
      console.log(response.data);
      if(response.data.success) {
        ToastsStore.success("Successfully changed shipping info.")
        this.props.userGet(response.data.user)
        this.props.history.push("/profile")
      } else {
        ToastsStore.error("Could not change shipping info.")
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.panel}>
          <div className={styles.panelDiv}>
              <TextField
                id="outlined-with-placeholder"
                label="First Name"
                InputLabelProps={{shrink: true}}
                placeholder="First Name"
                margin="normal"
                variant="outlined"
                value={this.state.firstName}
                onChange={this.handleTextField('firstName')}
                className={styles.field}
              />
              
              <TextField
                id="outlined-with-placeholder"
                label="Last Name"
                InputLabelProps={{shrink: true}}
                placeholder="Last Name"
                margin="normal"
                variant="outlined"
                value={this.state.lastName}
                onChange={this.handleTextField('lastName')}
                className={styles.field}
              />
              {//TODO MAKE SURE THIS IS A VALID ADDRESS
              }
              <TextField
                id="outlined-with-placeholder"
                label="Address"
                InputLabelProps={{shrink: true}}
                placeholder="Address"
                margin="normal"
                variant="outlined"
                value={this.state.address}
                onChange={this.handleTextField('address')}
                className={styles.field}
              />
              <TextField
                id="outlined-with-placeholder"
                label="Apt, suite, ect. (optional)"
                InputLabelProps={{shrink: true}}
                placeholder="Apt, suite, ect. (optional)"
                margin="normal"
                variant="outlined"
                value={this.state.apt}
                onChange={this.handleTextField('apt')}
                className={styles.field}
              />

              <TextField
                id="outlined-with-placeholder"
                label="City"
                InputLabelProps={{shrink: true}}
                placeholder="City"
                margin="normal"
                variant="outlined"
                value={this.state.city}
                onChange={this.handleTextField('city')}
                className={styles.field}
              />
              <div className={styles.rowDiv}>
                <TextField
                  id="outlined-with-placeholder"
                  label="State"
                  select
                  InputLabelProps={{shrink: true}}
                  placeholder="State"
                  margin="normal"
                  variant="outlined"
                  value={this.state.state}
                  onChange={this.handleTextField('state')}
                  SelectProps={{
                  native: true,
                  MenuProps: {
                    className: styles.menu,
                  },
                }}

                  className={styles.stateField}
                >
                  {states.map(option => (
                  <option key={option.abbreviation} value={option.abbreviation}>
                    {option.abbreviation}
                  </option>
                ))}
                </TextField>

                <TextField
                  id="outlined-with-placeholder"
                  select
                  label="Country"
                  InputLabelProps={{shrink: true}}
                  placeholder="Country"
                  margin="normal"
                  variant="outlined"
                  value={this.state.country}
                  onChange={this.handleTextField('country')}
                  className={styles.countryField}
                  SelectProps={{
                  native: true,
                  MenuProps: {
                    className: styles.menu,
                  },
                }}
              >
                {countries.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              </div>
              <TextField
                id="outlined-with-placeholder"
                label="Zip Code"
                InputLabelProps={{shrink: true}}
                type="number"
                placeholder="Zip Code"
                margin="normal"
                variant="outlined"
                value={this.state.zip}
                onChange={this.handleTextField('zip')}
                className={styles.field}
              />
          </div>
        </div>
        <Button variant="contained" color="primary" 
          onClick={this.onSaveClick}
          style={{
            color: 'white', 
            marginTop: 15,
            width: 213,
            height: 49,
          }} 
        >
          Save Changes
        </Button>
      </div>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditShipping)
