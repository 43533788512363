import React, { Component } from 'react';
import axios from 'axios'
import {serverLink} from '../../constants/serverLink'
import Checkbox from '@material-ui/core/Checkbox';


import styles from "./OrderFiles.module.css"
import fileIcon from '../../assets/fileIcon.png'

var fileDownload = require('js-file-download');

class FileCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  getFile = () => {
    axios.post(serverLink + 'downloadFile', {
      orderId: this.props.orderId,
      userId: this.props.userId,
      filename: this.props.filename,
    }).then((response) => {
      console.log(response);
      fileDownload(response, this.props.filename)
    }).catch((err) => {
      console.log(err);
    })
  }
  render() {
    const fileCardStyle = (this.props.checkValue) ? 
      [styles.fileCard, styles.greyBackground].join(' ') : styles.fileCard
    return (
      <div className={fileCardStyle}>
        <Checkbox 
          color="primary"
          checked={this.props.checkValue} 
          onChange={() => this.props.handleCheck(this.props.index)}
          value="checkedA" 
        />
        <img src={fileIcon} className={styles.icon} />
        <p onClick={this.getFile} className={styles.filename}>{this.props.filename}</p>
      </div>
    )
  }
}

export default FileCard
