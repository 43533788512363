exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FileUpload_container__3tzGq {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.FileUpload_orderDetails__1tyuS {\n  display: flex;\n  flex-direction: column;\n  margin-top: 25px;\n}\n\n.FileUpload_dropzoneContainer__HFpFw {\n  display: flex;\n  min-height: 30vh;\n  margin-top: 50px;\n}\n\n.FileUpload_dropzone__3Awi2 {\n  border-style: solid;\n  border-width: 2px;\n  min-height: 200px;\n  justify-content: center;\n  align-items: center;\n}\n\n.FileUpload_bold__3bWBB {\n  font-size: 45px;\n  font-family: Roboto;\n  font-weight: bold;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: normal;\n  letter-spacing: 1.1px;\n  text-align: center;\n  color: #000000;\n  padding-left: 60px;\n  padding-right: 60px;\n}\n\n.FileUpload_text__3TGwk {\n  size: 29px;\n  font-family: Roboto;\n  font-weight: 300;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: normal;\n  letter-spacing: 1.1px;\n  text-align: center;\n  color: #000000;\n}\n\n.FileUpload_fileIcon__22ole {\n  height: 30px;\n  width: 30px;\n}\n\n.FileUpload_ul__1c2Ws {\n  list-style-type: none;\n  display: flex;\n  flex-direction: column;\n  margin-right: 20px;\n}\n\n.FileUpload_li__2j8gd {\n  display: flex;\n  align-items: center;\n}\n", ""]);

// exports
exports.locals = {
	"container": "FileUpload_container__3tzGq",
	"orderDetails": "FileUpload_orderDetails__1tyuS",
	"dropzoneContainer": "FileUpload_dropzoneContainer__HFpFw",
	"dropzone": "FileUpload_dropzone__3Awi2",
	"bold": "FileUpload_bold__3bWBB",
	"text": "FileUpload_text__3TGwk",
	"fileIcon": "FileUpload_fileIcon__22ole",
	"ul": "FileUpload_ul__1c2Ws",
	"li": "FileUpload_li__2j8gd"
};