import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import {BarLoader} from 'react-spinners'
import { css } from '@emotion/core'

import styles from './Contact.module.css'

import locationImg from '../../assets/location_map.png'

var validator = require("email-validator")

//Style override for bar component
//Cant figure out how to do it in module file
const override = css`
  position: relative;
  left: 215px;
  top: 20px;
`

class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      phone: '',
      message: '',
      email: '',
      company: '',
      fetching: false,
    }
  }


  onSubmit = async () => {
    this.setState({fetching: true})
    const _name = this.state.name
    const _company = this.state.company
    const _phone = this.state.phone
    const _email = this.state.email
    const _message = this.state.message

    if(!validator.validate(_email)) {
      ToastsStore.warning("Please enter a valid email address.")
      this.setState({fetching: false})
      return;
    }
    if(_name.length <= 0 || _company.length <= 0) {
      ToastsStore.warning("Please fill out your name and company.")
      this.setState({fetching: false})
      return;
    }

    const params = {
      name: _name,
      email: _email,
      message: _message,
      company: _company,
      phone: _phone,
    }
    axios.post("https://polonorum-back-end.herokuapp.com/newEmail", {
      name: _name,
      company: _company,
      phone: _phone,
      email: _email,
      message: _message,
    }).then((response) => {
      console.log("Got a response");
      console.log(response);
      //Clear fields if successful
      ToastsStore.success("We successfully got your inquiry, we will respond to you soon!")
      this.setState({name: '', company: '', phone: '', email: '', message: '', fetching: false})
    }).catch((err) => {
      ToastsStore.error("We could not save the inquiry, try again later!")
      this.setState({fetching: false})
      console.log(err);
    })
  }

  handleChange = (name) => event => {
    this.setState({
      [name]: event.target.value
    })
  }
  render() {
    return (
      <div className={styles.mainContainer}>
        <div className={styles.halfContainer}>
          <p className={styles.redText}>Polonorum Contact</p>
          <div className={styles.infoContainer}>
            <div className={styles.boldContainer}>
              <p className={styles.boldText}>Address:</p>
            </div>
            <div className={styles.plainContainer}>
               <p className={styles.plainText}>
                Polonorum LLC <br/>
                3056 Scott Blvd, Ste C, <br/>
                Santa Clara, CA 95054
              </p>
            </div>
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.boldContainer}>
              <p className={styles.boldText}>Phone:</p>
            </div>
            <div className={styles.plainContainer}>
              <p className={styles.plainText}>
                1 (408) 638-0482
              </p>
            </div>
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.boldContainer}>
              <p className={styles.boldText}>Email:</p>
            </div>
            <div className={styles.plainContainer}>
              <p className={styles.plainText}>
                info@polonorum.com
              </p>
            </div>
          </div>
        </div>
        
        <div className={styles.halfContainer}>
          <form>
            <div className={[styles.singleLineInput, styles.firstInput].join(' ')}>
              <p className={styles.formLabel}>Name</p>
              <TextField
                id="Name"
                style={{ width: '600px', backgroundColor: 'white' }}
                variant="filled"
                value={this.state.name}
                onChange={this.handleChange('name')}
              />
            </div>
            <div className={[styles.singleLineInput, styles.notFirstInput].join(' ')}>
              <p className={styles.formLabel}>COMPANY</p>
              <TextField
                id="Name"
                style={{ width: '600px', backgroundColor: '#aaaaaa' }}
                variant="filled"
                placeholder="Leave it empty if you don't have a name"
                value={this.state.company}
                onChange={this.handleChange('company')}
              />
            </div>
            <div className={[styles.singleLineInput, styles.notFirstInput].join(' ')}>
              <p className={styles.formLabel}>TELEPHONE</p>
              <TextField
                id="Name"
                style={{ width: '600px', backgroundColor: '#aaaaaa'}}
                variant="filled"
                placeholder="Please, enter your land or mobile phone"
                value={this.state.phone}
                onChange={this.handleChange('phone')}
              />
            </div>
            <div className={[styles.singleLineInput, styles.notFirstInput].join(' ')}>
              <p className={styles.formLabel}>EMAIL</p>
              <TextField
                id="Name"
                style={{ width: '600px', backgroundColor: '#aaaaaa'}}
                variant="filled"
                placeholder="Your email address"
                value={this.state.email}
                onChange={this.handleChange('email')}
              />
            </div>
            <div className={[styles.singleLineInput, styles.notFirstInput].join(' ')}>
              <p className={styles.formLabel}>MESSAGE</p>
              <TextField
                id="Name"
                style={{ width: '600px', height: '188px', backgroundColor: '#aaaaaa' }}
                variant="filled"
                multiline
                rows="8"
                placeholder="Please, describe something about your project..."
                value={this.state.message}
                onChange={this.handleChange('message')}
              />
            </div>
            {!this.state.fetching ?
            <Button 
              variant="contained" 
              color="primary" 
              onClick={this.onSubmit}
              className={styles.submitButton}>
                Submit
            </Button>
            :
            <BarLoader
              css={override}
              color={'#123abc'}
              loading={true}
              sizeUnit={"px"}
              height={20}
              width={180}
              />
            }
          </form>
        </div>
      </div>
    )
  }
}

export default Contact
