import React, { Component } from 'react';
import global from "../../App.module.css"
import styles from "./Confirm.module.css"

class Confirm extends Component {
  constructor(props){
    super(props)

  }

  render() {
    const {firstName, lastName, busFirstName, busLastName, address, busAddress, state, busState, 
          apt, busApt, city, busCity, zip, busZip, last4} = this.props
    return (
      <div className={styles.container}>
        <div className={styles.textContainer}>
          <p className={styles.bold}>Shipping Address</p>
          <p className={styles.normal}>
            {firstName} {lastName} <br/>
            {address} <br/>
            {city}, {state} {zip} 
          </p>
        </div>

        <div className={styles.textContainer}>
          <p className={styles.bold}>Billing Address</p>
          <p className={styles.normal}>
            {firstName} {lastName} <br/>
            {address} <br/>
            {city}, {state} {zip} 
          </p>
        </div>

        <div className={styles.textContainer}>
          <p className={styles.bold}>Payment Method</p>
          <p className={styles.normal}>
            Visa ending in {last4}
          </p>
        </div>


      </div>
    )
  }
}

export default Confirm
