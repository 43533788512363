exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OrderPage_container__3cbXx {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  min-width: 500px;\n}\n\n.OrderPage_buttonDiv__SkDk4 {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 20px;\n  width: 30%;\n  min-width: 400px;\n  margin-bottom: 20px;\n}\n\n.OrderPage_singleButtonDiv__uK17l {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 20px;\n  width: 30%;\n  min-width: 400px;\n  margin-bottom: 20px;\n}\n\n.OrderPage_button__2mmnp {\n  width: 40%; \n  height: 73px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "OrderPage_container__3cbXx",
	"buttonDiv": "OrderPage_buttonDiv__SkDk4",
	"singleButtonDiv": "OrderPage_singleButtonDiv__uK17l",
	"button": "OrderPage_button__2mmnp"
};