exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Payment_container__3CZ1v {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n}\n\n.Payment_panel__19maI {\n  display: flex;\n  flex-direction: column;\n  width: 50%;\n  min-width: 400px;\n\n}\n\n.Payment_field__1qln- {\n  width: 80%;\n  font-family: Roboto;\n}\n\n.Payment_stateField__rUKI- {\n  width: 25%;\n}\n\n.Payment_countryField__1s1u2 {\n  width: 70%;\n}\n\n.Payment_expField__3lPNH {\n  width: 40%\n}\n\n\n.Payment_panelDiv__2pGZB {\n  display: flex;\n  flex-direction: column;\n  //background-color: red;\n  align-content: center;\n  justify-content: center;\n  width: 70%; \n}\n\n.Payment_panelDetails__1Dc6s {\n  display: flex;\n  //background-color: green;\n  justify-content: center;\n  align-items: center;\n}\n\n.Payment_rowDiv__1h1WE {\n  width: 80%;\n  display: flex;\n  justify-content: space-between;\n}\n\n.Payment_button__1bvhZ {\n  width: 15%;\n  height: 73px;\n  positon: relative;\n  top: 40px;\n}\n\n.Payment_marginRow__1GcCY {\n  margin-top: 20px;\n}\n\n\n.Payment_menu__2oXCH {\n  width: 200px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "Payment_container__3CZ1v",
	"panel": "Payment_panel__19maI",
	"field": "Payment_field__1qln-",
	"stateField": "Payment_stateField__rUKI-",
	"countryField": "Payment_countryField__1s1u2",
	"expField": "Payment_expField__3lPNH",
	"panelDiv": "Payment_panelDiv__2pGZB",
	"panelDetails": "Payment_panelDetails__1Dc6s",
	"rowDiv": "Payment_rowDiv__1h1WE",
	"button": "Payment_button__1bvhZ",
	"marginRow": "Payment_marginRow__1GcCY",
	"menu": "Payment_menu__2oXCH"
};