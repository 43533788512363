import actionCreator from './actionCreator'

export function saveQuotation(quantity, uniqueParts, smtParts, throughHoles, bgaPackages,
                              process, side, orderTime, totalCost) {
  return {
    type: 'SAVE_QUOTATION',
    quantity,
    uniqueParts,
    smtParts,
    throughHoles,
    bgaPackages,
    process,
    side,
    orderTime,
    totalCost,
  }
}


export const madeQuotation = actionCreator("USER_NEEDS_LOGIN", 'madeQuotation')
