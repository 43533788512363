import React, {Component } from 'react';
import Dropzone from 'react-dropzone';
import TextField from '@material-ui/core/TextField';


import styles from "./FileUpload.module.css"
import global from "../../App.module.css"

import fileIcon from '../../assets/fileIcon.png'

function FileList(props) {
  var keys = props.files.keys();
  const listFiles = props.files.map((file) => 
    <li className={styles.li}>
      <img src={fileIcon} className={styles.fileIcon}/>
      <p className={styles.testP}>
      {file.name}
      </p>
    </li>
  )
  return (
    <ul className={styles.ul}>
      {listFiles}
    </ul>
  )
}

function NoFiles(props) {
  return (
    <div>
      <p className={styles.text}>Drag and Drop</p>
      <p className={styles.text}>or</p>
      <p className={styles.text}>Click here</p>
    </div>
  )
}

class FileUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filesUploaded: false,
      files: [],
    };
  }

  handleChange = (name) => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  onDrop = (files) => {
    if (files.length > 0) {
      this.setState({files: files})
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.orderDetails}>
          
          <TextField
            id="outlined-with-placeholder"
            label="Project Name"
            placeholder="Project Name"
            margin="normal"
            variant="outlined"
            value={this.props.projectName}
            onChange={this.props.handleTextField('projectName')}
            className={styles.field}
          />
          <TextField
            id="outlined-with-placeholder"
            label="Purchase Order"
            placeholder="Purchase Order"
            margin="normal"
            variant="outlined"
            value={this.props.purchaseOrder}
            onChange={this.props.handleTextField('purchaseOrder')}
            className={styles.field}
          />
        </div>
        <div className={styles.dropzoneContainer}>
          <Dropzone onDrop={this.props.onDrop}>
            {({getRootProps, getInputProps}) => (
                  <section>
                    <div className={styles.dropzone} {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p className={styles.bold}>Upload Files</p>
                      {this.props.files.length > 0 ?

                        <FileList files={this.props.files}/>

                        :

                        <NoFiles/>

                      }
                    </div>
                  </section>
                )}
          </Dropzone>
        </div>
              </div>
    )
  }
}

export default FileUpload


