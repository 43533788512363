import React, { Component } from 'react';
import axios from 'axios'
import { bindActionCreators } from 'redux'
import { Provider, connect } from 'react-redux'
import Dropzone from 'react-dropzone';
import fileIcon from '../../assets/fileIcon.png'
import Button from '@material-ui/core/Button'
import styles from './OrderFiles.module.css'

import fileStyles from '../fileUpload/FileUpload.module.css'

import {serverLink} from '../../constants/serverLink'

const mapStateToProps = (state) => ({ user: state.authReducer.user,})

function FileList(props) {
  var keys = props.files.keys();
  const listFiles = props.files.map((file) => 
    <li className={fileStyles.li}>
      <img src={fileIcon} className={fileStyles.fileIcon}/>
      <p className={fileStyles.testP}>
      {file.name}
      </p>
    </li>
  )
  return (
    <ul className={styles.ul}>
      {listFiles}
    </ul>
  )
}

function NoFiles(props) {
  return (
    <div>
      <p className={styles.text}>Drag and Drop</p>
      <p className={styles.text}>or</p>
      <p className={styles.text}>Click here</p>
    </div>
  )
}

class UploadNewFiles extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filesUploaded: false,
      files: [],
    }
  }

  onDrop = async (acceptedFiles) => {
    let binaryFiles = []
    
    for(const file of acceptedFiles) {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result
      //console.log(binaryStr)
      let binaryFile = {filename: file.name, contentType: file.type, data: binaryStr}
      binaryFiles.push(binaryFile)
      }
      await reader.readAsBinaryString(file)

    }
    console.log("BINARY FILES");
    console.log(binaryFiles);
    console.log("AcceptedFiles");
    console.log(acceptedFiles);
    this.setState({files: acceptedFiles, binaryFiles})
  }


  onUploadClick = () => {
    const {orderId} = this.props.match.params
    console.log(this.state.files);
    axios.post(serverLink + 'newFiles', {
      userId: this.props.user.id,
      orderId: orderId,
      files: this.state.binaryFiles,
    }).then((response) => {
      console.log(response);
      if(response.data.success) {
        this.props.history.goBack()
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  render() {
    return (
      <div className={styles.dropzoneContainer}>
        <Dropzone onDrop={this.onDrop}>
          {({getRootProps, getInputProps}) => (
                <section>
                  <div className={styles.dropzone} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p className={styles.bold}>Upload Files</p>
                    {this.state.files.length > 0 ?

                      <FileList files={this.state.files}/>

                      :

                      <NoFiles/>

                    }
                  </div>
                </section>
              )}
        </Dropzone>
        <Button variant="contained" color="primary" 
          style={{
            marginTop: 30,
          }}
          onClick={this.onUploadClick}
        >
          Upload Files
        </Button>
  

      </div>
    )
  }
}

export default connect(mapStateToProps)(UploadNewFiles)
