
import React, { Component } from 'react';
import styles from "./Profile.module.css"
import Button from '@material-ui/core/Button'

class ProjectHeader extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        <div className={styles.orderTopPanel}>
          <div className={[styles.bold, styles.red, styles.activeMargin].join(' ')}>
            {this.props.headerName}
          </div>
          <Button variant="contained" color="inherit" 
          style={{backgroundColor: '#4E4E4E', 
              color: 'white', 
              marginTop: '11px', 
              marginRight: '107px'}} 
          onClick={this.props.onHeaderButtonClick}
          className={styles.monthButton}
          >
            {this.props.buttonTitle}
          </Button>
        </div>
        <div className={styles.orderInfo}>
          <div className={styles.firstDiv}>
            <p className={[styles.bold, styles.red].join(' ')}>Project Name</p>
          </div>
          <div className={styles.secondDiv}>
            <p className={[styles.bold, styles.red].join(' ')}>Progress</p>
          </div>
          <div className={styles.thirdDiv}>
            <p className={[styles.bold, styles.red].join(' ')}>Actions</p>
          </div>
          <div className={styles.fourthDiv}>
            {this.props.showEst &&
            <p className={[styles.bold, styles.red].join(' ')}>Est Delivery</p>
            }
          </div>
        </div>
      </div>

    )
  }
}

export default ProjectHeader
