import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom"
import  DocumentTitle  from "react-document-title"
import { Provider, connect } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import Home from './components/Home'
import Header from './components/Header'
import Email from './components/Email'
import Quotation from './components/quotation/Quotation'
import Contact from './components/contact/Contact'
import Login from './components/login/Login'
import Register from './components/register/Register'
import Services from './components/services/Services'
import Capabilities from './components/capabilities/Capabilities'
import Error from './components/Error'
import FileUpload from './components/fileUpload/FileUpload'
import Payment from './components/payment/Payment'
import Receipt from './components/receipt/Receipt'
import Order from './components/order/OrderPage'
import Profile from './components/profile/Profile'
import OrderFiles from './components/orderFiles/OrderFiles'
import {StripeProvider} from 'react-stripe-elements';
import {Elements} from 'react-stripe-elements';
import QuotationController from './components/quotationController/QuotationController'
import UploadNewFiles from './components/orderFiles/uploadNewFiles'
import EditPassword from './components/misc/editPassword'
import EditShipping from './components/misc/editShipping'
import PreviousOrders from './components/profile/previousOrders'
import {ToastsContainer, ToastsStore} from 'react-toasts';
import HttpsRedirect from 'react-https-redirect';
import ReactGA from 'react-ga';
import MetaTags from 'react-meta-tags'



import store from './redux/index'
import styles from "./App.module.css"

let persistor = persistStore(store)


function initializeReactGA() {
  ReactGA.initialize('UA-150296590-1');
  ReactGA.pageview('/homepage');
}


const divStyle = {
  alignItems: 'center',
}
class App extends Component {

  constructor() {
    super();
    this.state = {stripe: null};
  }




  componentDidMount() {
    if (window.Stripe) {
      this.setState({stripe: window.Stripe('pk_test_NMowPe7VfB0ifQOpiW4cs2UO00qyGJhoS1')});
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({stripe: window.Stripe('pk_test_NMowPe7VfB0ifQOpiW4cs2UO00qyGJhoS1')});
      });
    }
    
    initializeReactGA();
  }

  

  render() {
    return (
      <HttpsRedirect>
      <MetaTags>
        <title>Polonorum</title>
        <meta id="og-title" property="og:title" content="Polonorum" />
        <meta id="meta-description" name="description" content="Pcb assembly" />
        <meta id="meta-description" name="description" content="San Jose" />
        <meta id="meta-description" name="description" content="Bay Area" />
        <meta id="meta-description" name="description" content="Prototype" />
        <meta id="meta-description" name="description" content="low cost" />
        <meta id="meta-description" name="description" content="pay less" />

        <meta id="meta-description" name="description" content="printed circuit boards assembly" />
        <meta id="meta-description" name="description" content="contract manufacturing contract" />
        <meta id="meta-description" name="description" content="electronic" />
        <meta id="meta-description" name="description" content="manufacturing ems providers" />
        <meta id="meta-description" name="description" content="ems electronic manufacturing" />
        <meta id="meta-description" name="description" content="ems electronic manufacturing" />
        <meta id="meta-description" name="description" content="services surface mount" />
        <meta id="meta-description" name="description" content="technology smt" />
      </MetaTags>
      <StripeProvider stripe={this.state.stripe}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <DocumentTitle title="Polonorum">
                          <BrowserRouter>
                <div className={styles.container}>
                 <Header/>
                   <Switch>
                    <Route path="/" component={Home} exact/>
                    <Route path="/contact" component={Contact}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/register" component={Register}/>
                    <Route path="/services" component={Services}/>
                    <Route path="/capabilities" component={Capabilities}/>
                    <Route path="/profile" component={Profile}/>
                    <Route path='/files/:orderId' component={OrderFiles}/>
                    <Route path='/uploadFiles/:orderId' component={UploadNewFiles}/>
                    <Route path='/editPassword' component={EditPassword}/>
                    <Route path='/editShipping' component={EditShipping}/>
                    <Route path='/previousOrders' component={PreviousOrders}/>

                    <Elements>
                      <Route path="/quotation" component={QuotationController}/>
                    </Elements>
                    <Route component={Error} />

                   </Switch>
                  <ToastsContainer store={ToastsStore}/>
                </div>
              </BrowserRouter>
            </DocumentTitle>
          </PersistGate>
        </Provider>
      </StripeProvider>
      </HttpsRedirect>
    );
  }
}

export default App;
