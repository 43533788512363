import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Table from 'rc-table'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { NavLink, Redirect } from "react-router-dom"

import global from "../../App.module.css"
import styles from "./Quotation.module.css"

const processOptions = [
  {
    value: 'Lead Process'
  },
  {
    value: 'Lead Free Process'
  },
]

const sideOptions = [
  {
    value: 'Top Side',
  },
  {
    value: 'Bottom Side',
  },
  {
    value: 'Both Sides',
  },
]

const selectArray = [
  'Top Side', 'Bottom Side', 'Both Sides',
]

class Quotation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      quantity: 0,
      lines: 0,
      uniqueParts: 0,
      smtParts: 0,
      throughHoles: 0,
      bgaPackages: 0,
      quotation: 0,
      boardType: '',
      process: 'Lead process',
      side: 'Top Side',
      data: [{prod: '3 days'}, {prod: '5 days'}, {prod: '10 days'}],
      showTable: true,
      day3: false,
      day5: false,
      day10: false,
      total: 0,
      amountPerBoard1: 0,
      amountPerBoard2: 0,
      amountPerBoard3: 0,
      amountPerStencil1: 0,
      amountPerStencil2: 0,
      amountPerStencil3: 0,
      setupForThreeDays: 0,
      setupForFiveDays: 0,
      setupForTenDays: 0,
 
    }
    this.columns = [
      { title: 'Production Time', dataIndex: 'prod', key: 'prod', align: 'center', width: 150, },
      { title: 'Price per Board', dataIndex: 'board1', key: 'board1', align: 'center', width: 150, },
      //{ title: '3-5 boards', dataIndex: 'board5', key: 'board5', align: 'center', width: 150, },
      //{ title: '6-10 board', dataIndex: 'board10', key: 'board10', align: 'center', width: 150,}
    ]
  }

  componentDidUpdate(prevProps, prevState) {
    if((prevState.quantity != this.state.quantity ||
        prevState.uniqueParts != this.state.uniqueParts ||
        prevState.smtParts != this.state.smtParts ||
        prevState.throughHoles != this.state.throughHoles ||
        prevState.bgaPackages != this.state.bgaPackages ||
        prevState.process != this.state.process ||
        prevState.side != this.state.side ||
        prevState.day3 != this.state.day3 ||
        prevState.day5 != this.state.day5 ||
        prevState.day10 != this.state.day10
      )
      && 
      (this.state.day3 || this.state.day5 || this.state.day10))
    {
      this.calculateQuotation()
    }
  }


  handleInputChange = (event) => {
    this.setState({[event.target.name]: event.target.value})
  }

  handleChange = (name) => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  handleCheckChange = name => event => {
    if(name == "day3") {
      this.setState({ [name]: event.target.checked, day5: false, day10: false });
    }
    else if(name == "day5") {
      this.setState({ [name]: event.target.checked, day3: false, day10: false });
    }
    else if(name == "day10") {
      this.setState({ [name]: event.target.checked, day3: false, day5: false });
    }
  };

  toDollars = (num) => {
    return "$" + num.toFixed(2)
  }


  calculateQuotation = () => {


    //Number of boards = 0-20
    //Number unique Parts= 0-150
    //Number of smtParts = 0-3000
    //throughHoles = 0-50
    //BGA packages = 0-20
    let {quantity, lines, uniqueParts, smtParts, throughHoles, process,
          bgaPackages, boardType, side} = this.state

    if (quantity < 0) quantity = 0;
    if (uniqueParts < 0) uniqueParts = 0;
    if (smtParts < 0) smtParts = 0;
    if (throughHoles < 0) throughHoles = 0;
    if (bgaPackages < 0) bgaPackages = 0;


    //Refactor this with better variables names

    let stencilForThreeDays = (this.state.side == 'Both Sides') ? 400 : 200
    let stencilForFiveDays = (this.state.side == 'Both Sides') ? 350 : 175
    let stencilForTenDays = (this.state.side == 'Both Sides') ? 250 : 150


    let setupCost = 50 + (this.state.uniqueParts * 1)
    let setupForThreeDays = setupCost + stencilForThreeDays
    let setupForTenDays = setupCost + stencilForTenDays
    let setupForFiveDays = setupCost + stencilForFiveDays

    if(quantity == 0 || uniqueParts == 0 || (smtParts == 0 && bgaPackages == 0)) {
      setupCost = 0;
    }

    //console.log(this.state.quantity, this.state.smtParts, this.state.bgaPackages, this.state.throughHoles)
    let quotation1 = (this.state.smtParts * 0.16) + (this.state.bgaPackages * 10) + (this.state.throughHoles * 5) 
    let quotation2 = (this.state.smtParts * 0.14) + (this.state.bgaPackages * 9) + (this.state.throughHoles * 5) 
    let quotation3 = (this.state.smtParts * 0.12) + (this.state.bgaPackages * 8) + (this.state.throughHoles * 4) 
    let quotation5 =  (this.state.smtParts * 0.10) + (this.state.bgaPackages * 6) + (this.state.throughHoles * 3) 
    let quotation10 =  (this.state.smtParts * 0.09) + (this.state.bgaPackages * 5) + (this.state.throughHoles * 2)
    let quotation20 = (this.state.smtParts * 0.08) + (this.state.bgaPackages * 4) + (this.state.throughHoles * 2)
    let boardPrice = 0;
    let total = 0;

    if(this.state.quantity <= 0) {
      boardPrice = 0
    } else if(this.state.quantity <= 1) {
      boardPrice = quotation1
    } else if(this.state.quantity  <= 2) {
      boardPrice = quotation2
    } else if(this.state.quantity <= 3) {
      boardPrice = quotation3
    } else if(this.state.quantity <=5) {
      boardPrice = quotation5
    } else if(this.state.quantity <= 10) {
      boardPrice = quotation10
    } else if(this.state.quantity <= 20) {
      boardPrice = quotation20 
    } else {
      boardPrice = -1
    }
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    })

    let days = 0;
    if (boardPrice < 0) { //If more than 20 boards were ordered, set board and stencil to 0
      total = -1; //Total should be less than 0 so that we can conditionally render a string
      boardPrice = 0
      setupCost = 0
    } else if(this.state.day3) {
      total = (boardPrice * 2 * this.state.quantity) + setupForThreeDays;
      days = 3;
    } else if(this.state.day5) {
      total = (boardPrice * 1.5  * this.state.quantity) + setupForFiveDays;
      days = 5;
    } else if(this.state.day10) {
      total = (boardPrice * 1  * this.state.quantity) + setupForTenDays;
      days = 10;
    } else {
      console.log(this.state.day3, this.state.day5, this.state.day10)
      total = 0;
    }

    if(uniqueParts == 0) {
      boardPrice = 0;
      total = 0;
    }

    console.log("SIDE is", side);
    this.props.updateOrderProperties(quantity, uniqueParts, smtParts, 
      throughHoles, bgaPackages, process, side, total, days)

    this.setState({
      amountPerBoard1: boardPrice * 2,
      amountPerBoard2: boardPrice * 1.5,
      amountPerBoard3: boardPrice,
      amountPerStencil1: setupCost,
      amountPerStencil2: setupCost,
      amountPerStencil3: setupCost,
      total: total,
      quantity, uniqueParts, smtParts, throughHoles, bgaPackages,
      setupForThreeDays, 
      setupForTenDays, 
      setupForFiveDays,
    })
  }

  render() {
    const { classes } = this.props
    const errorQuantity = this.state.quantity > 20
    const errorBomLines = this.state.uniqueParts > 120
    const errorSmtParts = this.state.smtParts > 2000
    const errorThroughHoles = this.state.throughHoles > 10
    const errorBGA = this.state.bgaPackages > 10

    return (
      <div className={styles.Container}>
        <div className={styles.leftContainer}>
          <p className={[global.font, styles.title].join(' ')}>PCB Assembly Only Quotation</p>
          <form onSubmit={this.calculateQuotation}>
            <div className={styles.inputDiv}>
              <p className={styles.inputText}>Quantity:</p>
              <TextField
                id="outlined-number"
                value={this.state.quantity}
                type="number"
                className={styles.numberField}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange('quantity')}
              />
              <div className={styles.marginDiv}>
                {errorQuantity &&
                  <NavLink to="/contact" className={styles.contactText}>Please Contact Us</NavLink>
                }
              </div>
            </div>
            <div className={styles.inputDiv}>
              <p className={styles.inputText}>Number Of BOM Lines: </p>
              <TextField
                id="outlined-number"
                value={this.state.uniqueParts}
                type="number"
                className={styles.numberField}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange('uniqueParts')}
              />
              <div className={styles.marginDiv}>
                {errorBomLines &&
                  <NavLink to="/contact" className={styles.contactText}>Please Contact Us</NavLink>
                }
              </div>

            </div>
            <div className={styles.inputDiv}>
              <p className={styles.inputText}>Number of SMT Parts:</p>
              <TextField
                id="outlined-number"
                value={this.state.smtParts}
                type="number"
                className={styles.numberField}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange('smtParts')}
              />
              <div className={styles.marginDiv}>
                {errorSmtParts &&
                  <NavLink to="/contact" className={styles.contactText}>Please Contact Us</NavLink>
                }
              </div>
            </div>
            <div className={styles.inputDiv}>
              <p className={styles.inputText}>Number of Through-Hole Parts:</p>
              <TextField
                id="outlined-number"
                value={this.state.throughHoles}
                type="number"
                className={styles.numberField}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange('throughHoles')}
              />
              <div className={styles.marginDiv}>
                {errorThroughHoles &&
                  <NavLink to="/contact" className={styles.contactText}>Please Contact Us</NavLink>
                }
              </div>
            </div>
            <div className={styles.inputDiv}>
              <p className={styles.inputText}>Number of BGA Packages:</p>
              <TextField
                id="outlined-number"
                value={this.state.bgaPackages}
                type="number"
                className={styles.numberField}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange('bgaPackages')}
              />
              <div className={styles.marginDiv}>
                {errorBGA &&
                  <NavLink to="/contact" className={styles.contactText}>Please Contact Us</NavLink>
                }
              </div>
            </div>
            <div className={styles.inputDiv}>
              <p className={styles.inputText}>Process:</p>
              <TextField
                id="outlined-select-currency-native"
                select
                className={styles.numberField}
                value={this.state.process}
                SelectProps={{
                  native: true,
                }}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange('process')}
              >
                {processOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.value}
                      </option>
                ))}
              </TextField>
              <div className={styles.marginDiv}>
              </div>
            </div>
            <div className={styles.lastInputDiv}>
              <p className={styles.inputText}>Assembly Side:</p>
              <div className={styles.assemblySideButtons}>
                <TextField
                  id="outlined-select-currency-native"
                  select
                  className={styles.selectField}
                  value={this.state.side}
                  SelectProps={{
                    native: true,
                  }}
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange('side')}
                >
                  {sideOptions.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.value}
                        </option>
                  ))}
                </TextField>
                <Button 
                  variant="contained" 
                  color="primary" 
                  className={styles.quoteButton}
                  onClick={this.calculateQuotation}>
                    Quote Now
                </Button>
              </div>
            </div>
          </form>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.topRectangle}>
            <p className={[styles.topText, styles.timeMargin].join(' ')}>Time:</p>
            <p className={[styles.topText, styles.boardMargin].join(' ')}>$ Board:</p>
            <p className={[styles.topText, styles.stencilMargin].join(' ')}>$ Stencil/Setup:</p>
          </div>
          <div className={styles.middleRectangle}>
            <div className={styles.tableRectangle}>
              <div className={styles.checkBoxRect}>
                <FormControl component="fieldset">
                  <FormGroup className={styles.checkbox}>
                    <FormControlLabel className={styles.checkText}
                      control={
                        <Checkbox checked={this.state.day3} onChange={this.handleCheckChange('day3')} value="day3"/>
                      }
                        
                      label="3 Days"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={this.state.day5} onChange={this.handleCheckChange('day5')} value="day5"/>
                      }
                      label="5 Days"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={this.state.day10} onChange={this.handleCheckChange('day10')} value="day10"/>
                      }
                      label="10 Days"
                    />

                  </FormGroup>
                </FormControl>
              </div>
              <div className={styles.boardRect}>
                {true && 
                  <>
                    {this.state.quantity <= 5 ?
                      <p className={styles.total}>{this.toDollars(this.state.amountPerBoard1)}</p>
                      :
                      <p className={styles.moreThanFive}>Please Contact Us for more than 5 boards</p>
                    }
                    <p className={styles.total}>{this.toDollars(this.state.amountPerBoard2)}</p>
                    <p className={styles.total}>{this.toDollars(this.state.amountPerBoard3)}</p>
                  </>
                }
              </div>
              <div className={styles.stencilRect}>
                {true && 
                  <>
                    { this.state.quantity <= 5 ? //The semi colon is there to make sure the other text doesnt shift up.
                      <p className={styles.total}>{this.toDollars(this.state.setupForThreeDays)}</p>
                      :
                      <p className={styles.blank}>:</p>
                    }
                    <p className={styles.total}>{this.toDollars(this.state.setupForFiveDays)}</p>
                    <p className={styles.total}>{this.toDollars(this.state.setupForTenDays)}</p>
                  </>
                }
              </div>
            </div>
          </div>
          <div className={styles.bottomRectangle}>
            { true ? 
              <>
                <p className={styles.total}>Total Assembly Cost:</p>
                <p className={styles.total}>{this.toDollars(this.state.total)}</p>
              </>
              :
              <p className={styles.total}>{"Please contact us for a quotation for more than 20 boards."}</p>
            }
          </div>
          <p className={[global.font, styles.disclaimer].join(' ')}>
            Disclaimer: Prices are not final and are subject to review.
          </p>
          <div className={styles.orderButtonContainer}>
            <Button 
              variant="contained" 
              color="primary" 
              className={styles.orderButton}
              onClick={this.props.onPlaceOrderClick}>
                Place Order
            </Button>
          </div>


        </div>
      </div>
    )
  }
}

export default Quotation
