exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Confirm_container__1hHPf {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: left;\n}\n\n.Confirm_bold__26EAc {\n  font-size: 40px;\n  font-weight: 300;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: normal;\n  letter-spacing: 1.07px;\n  text-align: center;\n  color: #000000;\n}\n\n.Confirm_normal__YAn_x {\n  font-size: 20px;\n  font-weight: 300;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: normal;\n  letter-spacing: 0.47px;\n  color: #000000;\n}\n\n.Confirm_textContainer__2-smz {\n  text-align: left;\n  align-self: flex-start;\n}\n", ""]);

// exports
exports.locals = {
	"container": "Confirm_container__1hHPf",
	"bold": "Confirm_bold__26EAc",
	"normal": "Confirm_normal__YAn_x",
	"textContainer": "Confirm_textContainer__2-smz"
};