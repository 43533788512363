import React, { Component } from 'react';
import styles from './Home.module.css';

import pcb_stock from "../assets/pcb_stock_img.png"


class Home extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.imageDiv}>
          <div className={styles.descriptionView}>
            <div className={styles.descriptionText}>
              {paragraph1}
              <br/>
              {paragraph2}
              <br/>
              {paragraph3}
              <br/>
              {paragraph4}
            </div>
          </div>
        </div>
        <div className={styles.redTextDiv}>
          Efficient Electronic Assembly Services  <br/>
          Full Engineering Support <br/>
          Real Time Interaction <br/>
          Superior Customer Satisfaction
        </div>
      </div>
    );
  }
}

const paragraph1 = 'Polonorum Combines High-quality PCB Assembly Services With Timely Delivery'

const paragraph2 = 'Our Fast-Response Engineering Support Includes Component Procurement Services'

const paragraph3 = "The Online Quotation Wizard is Only 'Step One' in Our 'Real Time' Interaction"

const paragraph4 = 'We Understand Last Minute Design Changes and Guarantee a Flexible Response'


export default Home;
