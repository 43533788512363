import { applyMiddleware, compose, createStore } from 'redux'
import rootReducer from './reducers'
import thunkMiddleware from 'redux-thunk'
import logger from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default function configureStore(preloadedState) {
  //Only include logger if in development or test environments
  const middlewares = process.env.ENV === 'production' ? 
    [thunkMiddleware] : [logger, thunkMiddleware]
  if(process.env.NODE_ENV === 'production') {
    console.log("NOT IN PRODUCTION IN STORE");
  }
  const middlewareEnhancer = applyMiddleware(...middlewares)
  const enhancers = [middlewareEnhancer ]
  const composedEnhancers = compose(...enhancers)


  const store = createStore(persistedReducer, preloadedState, composedEnhancers)

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  }

  return store
}
