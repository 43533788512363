import React, { Component } from 'react';
import axios from 'axios'
import styles from "./Profile.module.css"
import ProjectHeader from './projectHeader'


class PreviousOrders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orders: null
    }
  }

  componentDidMount() {
  }

  onBackClick = () => {
    this.props.history.push('/profile')
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.previousOrderPanel}>
          <ProjectHeader
            onHeaderButtonClick={this.onBackClick}
            headerName={'Previous Orders'}
            buttonTitle={'Active Projects'}
            showOrderAgain={true}
          />
        </div>
      </div>
    )
  }
}

export default PreviousOrders
