import React, { Component } from 'react';
import Quotation from '../quotation/Quotation.js'
import OrderPage from '../order/OrderPage.js'

import { bindActionCreators } from 'redux'
import { Provider, connect } from 'react-redux'
import * as actions from '../../redux/actions/orderActions'
import * as authActions from '../../redux/actions/authActions'

import {ToastsContainer, ToastsStore} from 'react-toasts';
import { css } from '@emotion/core'

const mapStateToProps = (state) => ({ orderStatus: state.orderReducer, user: state.authReducer.user})
const mapDispatchToProps = (dispatch) => bindActionCreators({...authActions,...actions}, dispatch)


class QuotationController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      quantity: 0,
      uniqueParts: 0,
      smtParts: 0,
      throughHoles: 0,
      bgaPackages: 0,
      ledProcess: 'LED Process',
      assemblySide: 'Top Side',
      totalCost: 0,
      orderPage: false,
      days: 0,
    }
  }

  componentDidMount() {
    const {quantity, uniqueParts, smtParts, throughHoles, bgaPackages, ledProcess, assemblySide, orderTime, totalCost} = this.props.orderStatus
    this.setState({quantity, uniqueParts, smtParts, throughHoles, bgaPackages, ledProcess, assemblySide, orderTime, totalCost})

    if(this.props.orderStatus.madeQuotation && this.props.user != null) {
      this.setState({orderPage: true})
      this.props.madeQuotation(false)
    }

    this.props.madeQuotation(false)

    console.log("PROPS",this.props)
  }

  updateOrderProperties = (quantity, uniqueParts, smtParts, throughHoles, bgaPackages, 
    ledProcess, assemblySide, totalCost, days) => {

      this.setState({
        quantity, 
        uniqueParts,
        smtParts,
        throughHoles,
        bgaPackages,
        ledProcess,
        assemblySide,
        totalCost,
        days,
      })
  }

  goBackToQuote = () => {
    this.setState({
      quantity: 0,
      uniqueParts: 0,
      smtParts: 0,
      throughHoles: 0,
      bgaPackages: 0,
      ledProcess: 'LED Process',
      assemblySide: 'Top Side',
      totalCost: 0,
      days: 0,
      orderPage: false,
    })

  }

  onPlaceOrderClick = () => {
    const {quantity, totalCost} = this.state
    if(quantity <= 0 || totalCost <= 0) {
      ToastsStore.warning("Please make a quote first")
    }
    else {
      if(this.props.user == null) {
        this.props.madeQuotation(true)
        ToastsStore.warning("Please login or make an account to continue your order")
        this.props.history.push("/login")

      } else {
        console.log("WE HAVE USER", this.props.user);
        this.setState({orderPage: true})
      }
    }
  }

  handleChange = (name) => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  render() {
    let isGuest = false;
    if(this.props.user != null) {
      isGuest = this.props.user.email == 'guest@polonorum.com'
    }

    if(this.state.orderPage) {
      return (
        <div>
            
          <OrderPage boardQuantity={this.state.quantity}
                uniqueParts={this.state.uniqueParts}
                smtParts={this.state.smtParts}
                throughHoles={this.state.throughHoles}
                bgaPackages={this.state.bgaPackages}
                ledProcess={this.state.ledProcess}
                assemblySide={this.state.assemblySide}
                totalCost={this.state.totalCost}
                days={this.state.days}
                goBackToQuote={this.goBackToQuote}
                userID={this.props.user.id}
                isGuest={isGuest}
                history={this.props.history}
          />
        </div>
      )
    }
    else {
      return(
        <div>
          
          <Quotation updateOrderProperties={this.updateOrderProperties}
            onPlaceOrderClick={this.onPlaceOrderClick}
            handleChange={this.handleChange}
            boardQuantity={this.state.quantity}
            uniqueParts={this.state.uniqueParts}
            smtParts={this.state.smtParts}
            throughHoles={this.state.throughHoles}
            bgaPackages={this.state.bgaPackages}
            ledProcess={this.state.ledProcess}
            assemblySide={this.state.assemblySide}
            totalCost={this.state.totalCost}

          />
        </div>
      )
    }

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotationController)
