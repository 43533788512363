import React, { Component } from 'react';
import {withRouter} from 'react-router'
import { NavLink, Redirect } from "react-router-dom"
import polonorumLogo from '../assets/Polonorum_logo.png';
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import * as authActions from '../redux/actions/authActions.js'
import { bindActionCreators } from 'redux'
//import './Home.css';
import './Header.css'

const mapStateToProps = (state) => ({ user: state.authReducer.user,})
const mapDispatchToProps = (dispatch) => bindActionCreators(authActions, dispatch)

class Header extends Component {

  constructor(props) {
    super(props)
    this.state = {
      redirectHome: false
    }
  }


  logOut = () => {
    this.props.history.push('/')
    this.props.userLoggedOut()
  }

  renderLoginDiv = () => {
    if(this.props.user != null) {
      console.log("HAVE USER");
      return (
        <div>
          <NavLink to="/profile" className="Toolbar-Text">My Account</NavLink>
          <a onClick={this.logOut} className="Toolbar-Text">Logout</a>
        </div>
      )
    }
    else {
      console.log("NO USER");
      return (
        <div className="Register-Div">
          <NavLink to="/login" className="Toolbar-Text">Login</NavLink>
          <NavLink to="/register" className="Toolbar-Text">Register</NavLink>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="Header-Container">
        <div className="Top-Header">
          <div className="Left-Half">
            <img className="Logo" src={polonorumLogo} alt="Logo" />
              <br />
          </div>
          <div className="Right-Half">
            {/*<p className="Mobile Small-Text">Mobile</p>*/}
            {/*<p className="Work-Text Small-Text">Work</p>*/}
            <div className="Tagline-Div">
             <p className="Tagline-Text"> Prototype PCB Assembly and Engineering Services</p>
            </div>
          </div>
        </div>
        <div className="Toolbar">
          <div className="Toolbar-Items">
            <NavLink to="/" className="Toolbar-Text">Home</NavLink>
            <NavLink to="/services" className="Toolbar-Text">Services</NavLink>
            <NavLink to="/capabilities" className="Toolbar-Text">Capabilities</NavLink>
            <NavLink to="/quotation" className="Toolbar-Text">Quotation</NavLink>
            <NavLink to="/Contact" className="Toolbar-Text">Contact</NavLink>
            {this.renderLoginDiv()}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
