const initialState = {
  user: null,
  jwt: '',
  errMsg: '',
  error: false,
  loggedIn: false,
}

export default function reducer(state = initialState, action) {
  const { type, user, jwt, errMsg }  = action;
  switch(type) {
    case 'USER_LOGGED_IN':
      return {...state, user, jwt, error: false, loggedIn: true, errMsg: ''}
    case 'USER_LOGGED_OUT':
      return initialState
    case 'USER_GET':
      return {...state, user}
    case 'USER_ERROR':
      return {...state, error: true, errMsg, loggedIn: false}
    default:
      return state
  }
}
