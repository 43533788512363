import React, { Component } from 'react';

import ProgressBar from 'react-bootstrap/ProgressBar'
import Button from '@material-ui/core/Button'
import styles from "./Profile.module.css"
import LinearProgress from '@material-ui/core/LinearProgress';

class OrderCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
    this.orderReceivedColor = styles.grayArrow
    this.reviewedColor = styles.grayArrow
    this.kitAuditColor = styles.grayArrow
    this.assemblyColor = styles.grayArrow
    this.qaColor = styles.grayArrow
    this.shipmentPaymentColor = styles.grayArrow

    let orderStatus = (this.props.orderStatus == null) ? "Order Received" : this.props.orderStatus

    if(orderStatus == "In Progress") { //Needed until database is updated
      orderStatus = "Order Received"
    }

    if(orderStatus == "Shipment/Payment") {
      if(this.props.error) {
        this.shipmentPaymentColor = styles.redArrow
      }
      else {
        this.shipmentPaymentColor = styles.greenArrow
      }
      this.orderReceivedColor = styles.greenArrow
      this.reviewedColor = styles.greenArrow
      this.kitAuditColor = styles.greenArrow
      this.assemblyColor = styles.greenArrow
      this.qaColor = styles.greenArrow
    }

    if(orderStatus == "Q/A") {
      if(this.props.error) {
        this.qaColor = styles.redArrow
      }
      else {
        this.qaColor = styles.greenArrow
      }
      this.orderReceivedColor = styles.greenArrow
      this.reviewedColor = styles.greenArrow
      this.kitAuditColor = styles.greenArrow
      this.assemblyColor = styles.greenArrow

    }

    else if(orderStatus == "Assembly") {

      this.orderReceivedColor = styles.greenArrow
      this.reviewedColor = styles.greenArrow
      this.kitAuditColor = styles.greenArrow

      if(this.props.error) {
        this.assemblyColor = styles.redArrow
      }
      else {
        this.assemblyColor = styles.greenArrow
      }
    }

    else if(orderStatus == "Component Kit Audit") {
      this.orderReceivedColor = styles.greenArrow
      this.reviewedColor = styles.greenArrow
      if(this.props.error) {
        this.kitAuditColor = styles.redArrow
      }
      else {
        this.kitAuditColor = styles.greenArrow
      }

    }

    else if(orderStatus == "Documentation Reviewed") {
      this.orderReceivedColor = styles.greenArrow
       if(this.props.error) {
        this.reviewedColor = styles.redArrow
      }
      else {
        this.reviewedColor = styles.greenArrow
      }


    }

    else if(orderStatus == "Order Received") {
      if(this.props.error) {
        this.orderReceivedColor = styles.redArrow
      }
      else {
        this.orderReceivedColor = styles.greenArrow
      }
    }

  }

  render() {
    const {orderStatus, error} = this.props
    const textColor = this.props.error ? styles.red : styles.blue
    let actionText = "Edit Files"
    let actionHref = "/files/" + this.props.orderId
    if(error) {
      actionText = "Contact Us"
      actionHref = "/contact"
    }
    else if(orderStatus == "Assembly" || orderStatus == "Q/A") {
      actionText = ""
    }
    else if(orderStatus == "Shipment/Payment") {
      actionText = "Shipped"
      actionHref = "javascript:void(0)"
    }

    let orderDate = "TBD"
    if(this.props.orderDate) {
      let date = new Date(this.props.orderDate)
      console.log(date);
      orderDate = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
    }

    return (
      <div className={styles.orderCard} key={this.props.key}>
        <div className={styles.firstDiv}>
          <p className={[styles.bold, textColor].join(' ')}>{this.props.projectName}</p>
        </div>
        <div className={styles.secondDiv}>
          <div className={[styles.arrowDiv, this.orderReceivedColor].join(' ')}>
            Order<br/>
            Received
          </div>
          <div className={[styles.arrowDiv, this.reviewedColor].join(' ')}>
            Documentation<br/>
            Reviewed
          </div>
          <div className={[styles.arrowDiv, this.kitAuditColor].join(' ')}>
            Component<br/>
            Kit Audit
          </div>
          <div className={[styles.arrowDiv, this.assemblyColor].join(' ')}>
            Assembly
          </div>
          <div className={[styles.arrowDiv, this.qaColor].join(' ')}>
            Q/A
          </div>
          <div className={[styles.arrowDiv, this.shipmentPaymentColor].join(' ')}>
            Shipment/<br/>
            Payment
          </div>
        </div>
        <div className={styles.thirdDiv}>
          <a href={actionHref} className={[styles.bold, textColor].join(' ')}>
            {actionText}
          </a>
        </div>
        <div className={styles.fourthDiv}>
          {this.props.showOrderAgain ? 
          <Button variant="contained" color="inherit" 
          style={{backgroundColor: '#4285f4',
              width: '213px',
              height: '50px',
              color: 'white', }}>
            Order it Again
          </Button>
          :
          <p className={[styles.bold, textColor].join(' ')}>{orderDate}</p>
          }
        </div>
      </div>
    )
  }
}

export default OrderCard
