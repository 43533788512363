import React, { Component } from 'react';

import styles from "./Register.module.css"
import global from "../../App.module.css"
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { bindActionCreators } from 'redux'
import { Provider, connect } from 'react-redux'
import * as authActions from '../../redux/actions/authActions.js'

const mapStateToProps = (state) => ({ user: state.authReducer, madeQuotation: state.orderReducer.madeQuotation})
const mapDispatchToProps = (dispatch) => bindActionCreators(authActions, dispatch)

class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      password2: '',
      error: false,
      errMsg: '',
    }
  }
  
  componentDidMount() {
    this.props.userLoggedOut();
  }

  componentDidUpdate(prevProps, prevState) {
    if(!prevProps.user.loggedIn && this.props.user.loggedIn) {
      if(this.props.madeQuotation) {
        this.props.history.push("/quotation")
      }
      else {
        this.props.history.push("/")
      }
    }
    else if(this.props.user.error && this.props.user.errMsg != this.state.errMsg) {
      this.setState({errMsg: prevProps.user.errMsg, error: true})
    }
  }

  handleChange = (name) => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  redirectToLogin = () => {
    console.log("Clicked login button")
    this.props.history.push("/login")
  }

  onSubmit = () => {
    const { email, password, password2 } = this.state
    
    if(email === '' || password === '' || password2 === '') {
      this.setState({error: true, errMsg: 'Please fill out all fields!'})
      
    }
    else {
      if(this.state.password !== this.state.password2) {
        this.setState({error: true, errMsg: 'Passwords did not match'})
      }
      else {
        this.props.userSignUp(this.state.email, this.state.password)
      }
    }
  }


  render() {
    return (
      <div className={styles.container}>
        <p className={[styles.bold, global.font].join(' ')}>Register your account</p>
        <form className={styles.form}>
          <p className={styles.label}>E-Mail</p>
          <TextField
            id="Email"
            className={styles.textField}
            value={this.state.email}
            onChange={this.handleChange('email')}
            margin="normal"
            variant="outlined"
          />  
          <p className={styles.label}>Password</p>
          <TextField
            id="Password"
            className={styles.textField}
            value={this.state.password}
            onChange={this.handleChange('password')}
            margin="normal"
            type="password"
            variant="outlined"
          />
          <p className={styles.label}>Re-Password</p>
          <TextField
            id="Password2"
            className={styles.textField}
            value={this.state.password2}
            onChange={this.handleChange('password2')}
            margin="normal"
            type="password"
            variant="outlined"
          />
          <div className={styles.buttonDiv}>
            <Button variant="outlined" color="primary" className={styles.button} onClick={this.redirectToLogin}>
              Login
            </Button>
            <Button variant="contained" color="primary" className={styles.button} onClick={this.onSubmit}>
              Register
            </Button>
          </div>
        </form>
        { this.state.error &&
          <div>
            {this.state.errMsg}
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
