import React, { Component } from 'react';


import styles from "./Capabilities.module.css"
import global from "../../App.module.css"

class Capabilities extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <div className={styles.textDiv}>
            <p className={[global.font, styles.bold].join(' ')}>Types of Assembly</p>
            <p className={[global.font, styles.plain].join(' ')}>
              - Surface Mount Technology (SMT)<br/>
              - Mixed Technology Assembly<br/>
              - 2 sided SMT, Mixed Assembly<br/>
              - SMT single or double sided
            </p>
          </div>
          <div className={styles.textDiv}>
            <p className={[global.font, styles.bold].join(' ')}>Assembly Process</p>
            <p className={[global.font, styles.plain].join(' ')}>
              - Leaded Process<br/>
              - Lead-Free (RoHS) Process<br/>
              - No-Clean Process
            </p>
          </div>
          <div className={styles.textDiv}>
            <p className={[global.font, styles.bold].join(' ')}>Components</p>
            <p className={[global.font, styles.plain].join(' ')}>
            - Passive Parts, smallest 0201 <br/>
            - Fine Pitch as small as 15 Mils<br/>
            - BGA, QFN and Leadless Chips<br/>
            - Component packaging<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
            - Reels Package<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
            - Cut tape package<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
            - Tube and Tray<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
            - Loose parts and bulk<br/>
            </p>
          </div>
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.textDiv}>
            <p className={[global.font, styles.bold].join(' ')}>Board Dimensions</p>
            <p className={[global.font, styles.plain].join(' ')}>
            - Smallest size 0.5 in x 0.5 in, <br/>
            - Largest size 8 in x 8 in <br/>
            - Minimum PCB thickness 0.8mm<br/>
            </p>
          </div>
          <div className={styles.textDiv}>
            <p className={[global.font, styles.bold].join(' ')}>Test</p>
            <p className={[global.font, styles.plain].join(' ')}>
            - Visual inspection<br/>
            - Functional Testing
            </p>
          </div>
          <div className={styles.textDiv}>
            <p className={[global.font, styles.bold].join(' ')}>Other Capabilities</p>
            <p className={[global.font, styles.plain].join(' ')}>
              - Component sourcing<br/>
              - Parts Management<br/>
              - Electric Circuit Design<br/>
              - Engineering Services
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Capabilities
