exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".misc_container__2Im5n {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 80vh;\n  flex-direction: column;\n}\n\n.misc_redText__3eQi1 {\n  font-family: Roboto;\n  font-size: 35px;\n  font-weight: bold;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: normal;\n  letter-spacing: -0.28px;\n  color: #c5393a;\n}\n\n.misc_textField__2CefB {\n  width: 20%;\n\n}\n\n.misc_panel__34FRA {\n  display: flex;\n  flex-direction: column;\n  width: 50%;\n  justify-content: center;\n  align-items: center;\n  min-width: 400px;\n}\n\n.misc_panelDiv__2KqI8 {\n  display: flex;\n  flex-direction: column;\n  align-content: center;\n  justify-content: center;\n  width: 70%; \n}\n\n.misc_rowDiv__1CBBI {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n}\n\n.misc_stateField__2Eu3t {\n  width: 25%;\n}\n\n.misc_countryField__3UrS5 {\n  width: 70%;\n}\n\n", ""]);

// exports
exports.locals = {
	"container": "misc_container__2Im5n",
	"redText": "misc_redText__3eQi1",
	"textField": "misc_textField__2CefB",
	"panel": "misc_panel__34FRA",
	"panelDiv": "misc_panelDiv__2KqI8",
	"rowDiv": "misc_rowDiv__1CBBI",
	"stateField": "misc_stateField__2Eu3t",
	"countryField": "misc_countryField__3UrS5"
};